<template>
    <b-container>
        <b-row>
            <h3 class="mx-auto text-center formTitle qs-title-width mt-4">Política de Cookies</h3>
        </b-row>
        <b-row>
            <b-col class="formText mx-3 mb-5 mt-2">
                <p class="my-3">Las cookies son una pequeña información enviada por un sitio web y almacenada en el navegador del usuario, de manera que el sitio web puede consultar la actividad
                previa del navegador.</p>
                <p class="my-3">Sus principales funciones son:</p>
                <p class="my-3">- Recordar accesos: conocer si ya se ha visitado la página para actuar en consecuencia, por ejemplo mostrando o no cierto contenido, recordar a un usuario, etc.
                Cuando un usuario introduce su nombre de usuario y contraseña, se almacena una cookie para que no tenga que estar introduciéndolas para cada página del servidor.
                Sin embargo, una cookie no identifica a una persona, sino a una combinación de computadora de la clase de computación-navegador-usuario.</p>
                <p class="my-3">- Conocer información sobre los hábitos de navegación, e intentos de spyware (programas espía), por parte de agencias de publicidad y otros.
                Esto puede causar problemas de privacidad y es una de las razones por la que las cookies tienen sus detractores.</p>
                <p class="my-3">- Para analizar las cookies, esta página utiliza Google Analytics: www.google.com/analytics/Enlace a Google Analytics y
                http://www.google.es/intl/es/analytics/privacyoverview.html.Enlace a Marketingplatform de Google Esta aplicación ha sido desarrollada por Google,
                y analiza el número de personas que entran en el dominio. No obtiene datos de los nombres o apellidos de los usuarios ni de la dirección postal desde donde se
                conectan Se pueden obtener informes como el seguimiento de usuarios exclusivos, el rendimiento del segmento de usuarios, los resultados de las diferentes
                campañas de marketing online, las sesiones por fuentes de tráfico, tasas de rebote, duración de las sesiones, contenidos visitados, conversiones (para ecommerce),
                etc. La información recabada mediante esta aplicación es muy útil para mejorar los servicios de nuestra página web.</p>
                <p class="my-3">- Para permitir, conocer, bloquear o eliminar las cookies instaladas en tu equipo puedes hacerlo mediante la configuración de las opciones
                del navegador instalado en su ordenador.</p>
                <p class="my-3">Por ejemplo puedes encontrar información sobre cómo hacerlo en el caso que uses como navegador:</p>
                <p class="my-3">- Firefox desde aquí: http://support.mozilla.org/es/products/firefox/cookiesEnlace a las cookies de Mozilla Firefox</p>
                <p class="my-3">- Chrome desde aquí: http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647Enlace a las cookies de Google Chrome</p>
                <p class="my-3">- Explorer desde aquí: http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9Enlace a las cookies de Internet Explorer 9</p>
                <p class="my-3">- Safari desde aquí: http://support.apple.com/kb/ph5042Enlace a las cookies de Safari</p>
                <p class="my-3">- Opera desde aquí: http://help.opera.com/Windows/11.50/es-ES/cookies.html</p>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
    name: 'Cookies',
    created(){
        document.title = 'Política de Cookies'
    }
}
</script>

