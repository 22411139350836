<template>
    <b-container>
        <h4 class="notAllowed text-center">La colección que intenta buscar no está disponible. Busque entre nuestras colecciones disponibles, en la pestaña colecciones del menú.</h4>
    </b-container>
</template>
<script>
export default {
  name: 'WrongCollection',
  computed:{
    getProducts(){
      
      return this.$root.$emit('loader', false)
    }
  },

  mounted(){
    this.$root.$emit('loader', false)
    document.title = 'Tony Bambino'
  }
}
</script>