<template>
    <b-container>
        <b-row>
			<h3 class="notAllowed text-center">Su cuenta ha sido registrada. Le hemos enviado un correo de confirmación, por favor revise su email para activar su cuenta de usuario.</h3>
		</b-row>   
    </b-container>
</template>
<script>
export default {
    name: 'Registered',
    created(){
        if(sessionStorage.getItem('tbStore.jwt') === null){
            this.$router.push('error')
        }
        document.title = 'Usuario Registrado'
    }
}
</script>