<template>
  <div>
    <div v-if="this.billingAdress.length === 0">
      <h6 class="text-center abril-fatface my-5"> {{message}}</h6>
      <hr>
    </div>
    <div v-else>
      <div v-for="(info, Index) in billingAdress" :key="Index">
        <tr>
          <td class="mt-4 card-label adr-td-width">
            <label class="w-100">
              <input type="radio" class="filled-in" :id="info.id" v-model="picked" :value="info.id" @click="setSelectedBillingAdress(info.id, info.customer)"/>
              <span>
                <p class="abril-fatface">{{ info.name + ' ' + info.surname }}</p>
                <p class="text-dark">{{info.adress + ', ' + info.postal_code + ', ' + info.city + ' (' + info.region + ')'}}</p>
              </span> 
            </label>
          </td>
          <button v-if="$route.name === 'Adresses'" class="delete-button mb-4" @click="deleteBillingAdress(info.id)">
            <i class="material-icons">delete</i>
          </button>
          <router-link :to="{name: 'BillingAdressFormFilled', params:{user: info.user, id: info.id}}">
            <b-button class="grayButton deleteButton">editar</b-button>
          </router-link>
        </tr> 
        <hr>
      </div>
    </div>
    <router-link class="float-right" :to="{name: 'BillingAdressForm'}">
      <b-button class="grayButton mt-4 mb-2">Agregar  dirección</b-button>
    </router-link>
  </div> 
</template>
<script>
export default {
  name: 'BillingAdressSelector',
  data(){
    return{
      billingAdress: [],
      picked: '',
      message:'Cargando direcciones...'
    }
  },
  created(){
    this.getBillingAdress()
  },
  computed:{
    checkBillingAdress(){
      let billingAdress = this.billingAdress.find(x=> x.selected === 1)
      if(billingAdress){
        return billingAdress['id']
      }
      return false
    }
  },
  watch:{
    checkBillingAdress:{
      handler: function (newVal){
        this.picked = newVal
      }
    }
  },
  methods:{
    setSelectedBillingAdress(id, customer){
      this.axios.post('setSelectedBillingAdress', { id, customer }).then(() => {
        this.$root.$emit('billinInfo', this.axios.get('getBillingAdressInfo/' + this.$store.getters.username))
      })
    },
    getBillingAdress(){
      this.axios.get('getBillingAdressInfo/' + this.$store.getters.username).then(response=> { 
        if(response.data.length === 0){
          this.message = 'No tiene ninguna dirección de facturación. Por favor, agregue una.'
        }
        this.billingAdress = response.data
      })
    },
    deleteBillingAdress(id){
      this.axios.delete('deleteBillingAdress/' + id).then(()=>{
        let adress = this.billingAdress.map(adress => {
          if(adress.id === id){
            return this.billingAdress.indexOf(adress.id)
          }
        })
        this.billingAdress.splice(adress, 1)
        this.billingAdress.find(adress => adress.selected === 0)
        this.getBillingAdress()
      }).catch(error=>{
        if(error.response){
          alert('ha habido un error, recarge la página')
        }
      })
    }
  },
}
</script>
