<template>
    <b-container>
        <b-row class="my-5">
			<h3 class="notAllowed text-center my-5">Su contraseña ha sido cambidada</h3>
		</b-row>   
    </b-container>
</template>
<script>
export default {
  name: 'PasswordRecoverySuccess',
  data(){
    return{
      token: this.$route.params.token
    }
  },
  created(){
    if(sessionStorage.getItem('scss_t') !== this.token){
      this.$router.push('/error')
    }
    document.title = 'Contraseña cambiada en Tony Bambino'
  }
}
</script>


