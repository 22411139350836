<template>
    <cookie-law closeButtonText="Aceptar Cookies">
      <div slot="message" class="font cookies-font-size">
        <p>Utilizamos cookies y herramientas similares que son necesarias para permitirte comprar, mejorar tus experiencias de compra y 
        proporcionar nuestros servicios, según se detalla en nuestro Aviso de cookies. También utilizamos estas cookies para entender 
        cómo utilizan los clientes nuestros servicios (por ejemplo, mediante la medición de las visitas al sitio web) con el fin de poder realizar mejoras.
        Si estás de acuerdo, también utilizaremos las cookies para complementar tu experiencia de compra en nuestra web. 
        Para obtener más información sobre cómo y para qué fines Tony Bambino utiliza la información personal (como el historial de pedidos), 
        visita nuestro Aviso de privacidad. <router-link :to="{name: 'Cookies'}">Política de Cookies</router-link></p>
      </div>
    </cookie-law>
</template>
<script>
  import CookieLaw from 'vue-bootstrap-cookie-law'
  export default {
    components: { 
      CookieLaw 
    },
  }
</script>
<style>
  .cookie-button{
    font-family:'Abril Fatface';
    margin-bottom:20%;
    font-size: 1rem!important;
    width: 18%;
  }
  .cookie-buttons{
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    align-self: center;     
  }
  .cookie-notice{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
  }
  .cookie-content{
    width: 83%;
    margin-left: 2%;
    margin-top:1%
  }
  .cookies-font-size{
    font-size: 1rem!important;
  }
  
  @media (min-width:0px) and (max-width: 575px) {
    .cookie-content{
      margin-top: 5%;
      margin-left: auto;
      margin-right: auto;
    }
    .cookies-font-size{
      font-size: 0.7rem!important;
    }
    .cookie-button{
      font-family:'Abril Fatface';
      margin-top:2%;
      font-size: 0.9rem!important;
    }
  }

  @media (min-width:576px) and (max-width: 767px) {
    .cookies-font-size{
      font-size: 0.8rem!important;
    }
    .cookie-content{
      margin-left: 0%;
      width: 68%;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      text-align:left
    }
    .cookie-button{
      font-family:'Abril Fatface';
      margin-top:2%;
      font-size: 0.9rem!important;
    }
   
  }

  @media (min-width:768px) and (max-width: 1200px) {
    .cookies-font-size{
      font-size: 0.8rem!important;
    }
    .cookie-button{
      font-family:'Abril Fatface';
      margin-top:2%;
      vertical-align:down;
      width:180px;
      font-size: 0.9rem!important;
    }
  }
</style>