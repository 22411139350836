<template>
  <div>
    <div class="my-5">
			<h3 class="mx-auto formTitle text-center s-title-width my-5">Detalles de Mi Cuenta</h3>
		</div>
    <b-card class="mt-3 mb-5 mx-auto da-card-width">
      <ValidationObserver ref="form" >
        <b-form v-for="(info, Index) in userInfo" :key="Index" @submit.prevent="setUsersInfo(info.usuario, info.business_name, info.email, info.telephone, info.vat, info.num_cliente, info.recargo)" >
          <h4 class="mt-3 text-center login-title">Información de Usuario</h4>
          <b-row class="mt-5">
            <b-col>
              <FormInputImage class="mb-4 form-input-image" :value="info.avatar_img" :width="150" :height="150" :imgRoute="'https://ctworker.com/images/avatar/'" :defaultImg="'user_icon.png'"></FormInputImage>
            </b-col>
            <b-col>
              <p class="font mt-3">Edita tus datos de contacto y agrega una imagen de tu negocio</p>
            </b-col>
          </b-row>
          <h6 class="my-4">Nombre de usuario: <span class="font">{{ info.usuario }}</span></h6>
          <h6 class="mb-5">Email: <span class="font">{{info.email}}</span></h6>
          <ValidationProvider rules="required">
            <div class="input-field" slot-scope="{ valid, errors }">
              <b-form-input id="business_name" v-model="info.business_name" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
              <label for="business_name" :class="info.business_name !== null ? 'active' : ''">Nombre de Empresa</label>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required|numeric">
            <div class="input-field" slot-scope="{ valid, errors }">
              <b-form-input id="tel" v-model="info.telephone" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
              <label for="tel" :class="info.telephone !== null ? 'active' : ''">Teléfono</label>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required|vat|espacios">
            <div class="input-field" slot-scope="{ valid, errors }">
              <label for="vat" :class="info.vat !== null ? 'active' : ''">Número CIF</label>
              <b-form-input :state="errors[0] ? false : (valid ? true : null)" v-model="info.vat" type="text"></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required">
            <b-row class="input-field" v-if="info.recargo === null" slot-scope="{ errors }">
              <b-col class="pt-2">
                <span class="mt-3 sr-text-width">Recargo de Equivalencia</span>
              </b-col>
              <b-col >
                <select class="form-control sr-input-width text-center" v-model="selected" :state="errors[0] ? false : null">
                  <b-form-select-option :value="null">Elegir</b-form-select-option>
                  <b-form-select-option :value="1" :state="errors[0] ? false : null">Si</b-form-select-option>
                  <b-form-select-option :value="0" :state="errors[0] ? false : null">No</b-form-select-option>
                </select>
                <span class="pl-2 text-danger">{{ errors[0] }}</span>
              </b-col>
            </b-row>
          </ValidationProvider>
          <h6 v-if="info.recargo === 1" class="text-center"> Usted tiene recargo de equivalencia del 5,2% en sus compras</h6>
          <h6 v-if="info.recargo === 0" class="text-center">Usted no tiene recargo de equivalencia</h6>
          <hr>
          <b-alert variant="success" class="my-3" :show="dismissCountDown"  @dismissed="dismissCountDown = 0">Sus datos de usuario se han cambiado</b-alert>
          <b-alert variant="danger" class="my-3" :show="dismissCountDown2"  @dismissed="dismissCountDown = 0">{{ errors }}</b-alert>
          <b-row>
            <b-button class="mt-4 mb-5 mx-auto btn-form edit-button" variant="primary" type="submit">Editar</b-button>
          </b-row>
          <b-list-group>
            <b-list-group-item class="pt-3">
              <span>Cambiar la contraseña de tu cuenta</span><router-link class="float-right" :to="{ name: 'PasswordChangeForm', params:{ user: user, token: token}}">
              <b-button class="grayButton mb-3">Editar</b-button></router-link>
            </b-list-group-item>
          </b-list-group>
       </b-form>
      </ValidationObserver>
    </b-card>
    <UserParamLink class="mb-5" :message="'Volver a Mi Cuenta'" :url="'MyAccount'"></UserParamLink>
  </div>
</template>
<script>
import FormInputImage from '../formComponents/FormInputImage.vue'
import { getRandomString } from '../../encryption/encryption'
import UserParamLink from '../buttons/UserParamLink'
export default {
  name: 'MyAccountDetails',
  components:{
    FormInputImage,
    UserParamLink
  },
  data(){
    return{
      userInfo: [],
      input: '',
      user:this.$store.getters.username,
      dismissSecs: 10,
      dismissCountDown: 0,
      dismissCountDown2: 0,
      token: getRandomString(1077),
      selected: null,
      errors: ''

    }
  },
  created(){
    this.$root.$emit('loader', true)
    this.axios.get('getInfoByUser/' + this.$store.getters.username).then(response => {
      this.userInfo = response.data
      this.$root.$emit('loader', false)
    })
    document.title = 'Detalles de mi Cuenta'
  },
  methods:{
    async setUsersInfo(username, business_name, email, telephone, vat, customer, surcharge){
      if(surcharge === null || surcharge === ''){
        surcharge = this.selected
      }
      const success = await this.$refs.form.validate()
      if(!success){
        this.dismissCountDown2 = this.dismissSecs
        this.errors = 'Su formulario tiene errores'
        return false
      }
      this.$root.$emit('loader', true)
      this.axios.put('setUserInfo', { username, business_name, email, telephone, vat, customer, surcharge }).then(() =>{
        this.dismissCountDown = this.dismissSecs
        this.axios.get('getInfoByUser/' + this.$store.getters.username).then(response => (this.userInfo = response.data))
        this.$root.$emit('loader', false)
      }).catch(error=>{
        if(error.response.status){
          this.errors = 'Ha habido un error con el servidor, por favor recarge la página'
          this.dismissCountDown2 = this.dismissSecs
        }
        this.$root.$emit('loader', true)
      })
    },
  },
}
</script>

