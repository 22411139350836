<template>
  <b-container>
    <b-row class="my-5">
			<h3 class="mx-auto formTitle qs-title-width my-5">Mis Pedidos</h3>
		</b-row>
    <b-card v-if="orders.length !== 0" bg-variant="light" class="mt-5">
      <b-list-group  v-for="(info, Index) in orders" :key="Index">
        <b-list-group-item><router-link class="font" :to="{ name: 'OrderDetails', params:{ user: info.user, orderNumber: info.num_pedido }}">Pedido nº 8300-{{ info.num_pedido }} |
          <span v-if="info.estado === 0" class="text-info">Preparándose</span>
          <span v-else-if="info.estado === 1" class="text-info">Enviado</span>
          <span v-else-if="info.estado === 2" class="text-success">Recibido</span>
          <span v-else-if="info.estado === 3" class="text-danger">Cancelado</span>
          <span v-else-if="info.estado === 4" class="text-danger">Error en Pedido</span>
          <span class="date">{{ info.fecha | moment(' D [de] MMMM Y')}}</span></router-link>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <h4 v-else class="font-abril-fatface text-center border-top border-bottom py-5">{{message}}</h4>
  <br>
      <br>
      <br>
    <UserParamLink class="my-5" :message="'Volver a Mi Cuenta'" :url="'MyAccount'"></UserParamLink>
  </b-container>
</template>
<script>
import UserParamLink from '../buttons/UserParamLink'
export default {
  name: 'Orders',
  components:{
    UserParamLink
  },
  data(){
    return{
      orders: [],
      message: 'Cargando sus pedidos'
    }
  },
  methods:{
    getCustomerOrders(){
      this.axios.get('getAllCustomerOrders/' + this.$store.getters.username).then(response => {
        this.$root.$emit('loader', this.loader)
        if(response.data.length === 0){
          return this.message = 'No ha realizado nigún pedido todavía'
        }
        this.orders = response.data
      })
      this.$root.$emit('loader', !this.loader)
    },  
  },
  created(){
    this.axios.delete('deleteUserUnpaidOrders/' + this.$store.getters.username)
    this.getCustomerOrders() 
    document.title = 'Pedidos' 
  }
}
</script>
<style>
  .date{
    float: right;
  }
  @media (min-width:0px) and (max-width: 575px) {
    .date{
      margin-right:10%!important
    }
  }
</style>
