<template>
  <b-container class="mt-3">
    <b-card class="mb-5 pb-0 mr-3 mx-auto col-12 pasword-card-width">
      <ValidationObserver ref="passwordChange">
        <b-form  @submit.prevent="changePassword">
          <h4 class="text-center mb-5 mt-2 login-title">Cambiar de Contraseña</h4>
          <ValidationProvider rules="required|minmax:8,contraseña,caracteres, La|espacios" name="password">
            <div class="input-field"  slot-scope="{ valid, errors }">
              <label for="password">Contraseña Actual</label>
              <b-form-input aria-describedby="password-help-block" type="password" id="password" :state="errors[0] ? false : (valid ? true : null)" v-model="password" ></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required|minmax:8,contraseña,caracteres, La|espacios" name="newPassword">
            <div class="input-field"  slot-scope="{ valid, errors }">
              <label for="newPassword">Nueva Contraseña</label>
              <b-form-input id="newPassword" ref="newPassword" aria-describedby="password-help-block" :state="errors[0] ? false : (valid ? true : null)" v-model="newPassword" type="password"></b-form-input>
              <b-form-invalid-feedback>{{ errors[0]}}</b-form-invalid-feedback>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required|minmax:8,contraseña,caracteres, La|espacios" name="repeatPass">
            <div class="input-field"  slot-scope="{ valid, errors }">
              <label for="repeatPass">Repetir Contraseña</label>
              <b-form-input id="repeatPass" aria-describedby="password-help-block" :state="errors[0] ? false : (valid ? true : null)" v-model="repeatPass" type="password"></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </div>
          </ValidationProvider>
          <b-overlay id="overlay-background" class="mx-auto d-block passwcha-overlay-width my-5" :show="show" rounded="sm">
            <b-button type="submit" class="btn-form mx-auto" variant="primary">Cambiar Contraseña</b-button>
          </b-overlay>
        </b-form>
      </ValidationObserver>
      <b-alert variant="danger" class="my-3" :show="dismissCountDown"  @dismissed="dismissCountDown = 0">{{ errors }}</b-alert>
    </b-card>
    <UserParamLink :message="'Volver a Detalles de Mi Cuenta'" class="my-5" :url="'MyAccountDetails'"></UserParamLink>
  </b-container>
</template>
<script>
import {setCookie} from '../../cookies'
import { encryptString, getRandomString } from '../../encryption/encryption'
import UserParamLink from '../buttons/UserParamLink'
export default {
  name: 'PasswordChangeForm',
  components:{
    UserParamLink
  },
  data(){
    return{
      show:false,
      repeatPass:'',
      newPassword:'',
      password:'',
      token: this.$route.params.token,
      dismissSecs: 10,
      dismissCountDown: 0,
      errors: ''
    }
  },

  created() {
    document.title = 'Cambiar Contraseña'
  },

  methods: {
    async changePassword() {
      const success = await this.$refs.passwordChange.validate()
      let password = encryptString(this.password)
      let usuario = this.$store.getters.username
      this.show = true

      if (!success) {
        this.dismissCountDown = this.dismissSecs
        this.errors = 'Su formulario tiene errores'
        return false
      }
      if (this.password === this.newPassword){
        return this.$refs.passwordChange.setErrors({
          newPassword: ['La nueva contraseña no puede ser igual a la anterior']
        })
      }

      this.axios.post('checkPassword', { usuario, password }).then(response => {
        let newPass = encryptString(this.newPassword)
        let repeatPass = encryptString(this.repeatPass)
        this.show = false
        if (this.newPassword != this.repeatPass || this.newPassword.length <= 0) {
          this.show = false
          return this.$refs.passwordChange.setErrors({
            repeatPass: ['Las contraseñas no coinciden']
          })
        }
        localStorage.setItem('tbStore.jwt', JSON.stringify(response.data.token))
        this.axios.post('changePass', { usuario, newPass, repeatPass }).then(() => {
          let token = getRandomString(1088)
          setCookie('s_nrxzs', token, 0.001)
          this.$router.push({name: 'PasswordChanged', params: { user: usuario }})
        }).catch(error => {
          if (error.response.status === 401) {
            this.$refs.passwordChange.setErrors({
              repeatPass: ['Las contraseñas no coinciden']
            })
          }
          this.show = false
          return false
        })
      }).catch(error => {
        if (error.response.status === 403) {
          this.$refs.passwordChange.setErrors({
            password: ['Contraseña incorrecta. Inserte una contraseña correcta']
          })
        }
        if (error.response.status === 500) {
          this.dismissCountDown = this.dismissSecs
          this.errors = 'Ha habido un error en la conexión, por favor recargue la página'
        }
        this.show = false
        return false
      })
    },
  },
}
</script>


