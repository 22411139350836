<template>
    <div>
        <table class="table ml-0 mt-5">
            <thead>
                <tr class="abril-fatface">
                    <th scope="col"></th>
                    <th scope="col" class="text-left">MODELO</th>
                    <th scope="col">COLOR</th>
                    <th scope="col">PRECIO</th>
                    <th scope="col" class="text-center w-25">CANTIDAD</th>
                    <th scope="col" class="text-center">SUBTOTAL</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody scope="row">
                <tr v-for="(order, Index) in orderDetails" :key="Index">
                    <td>
                        <router-link :to="{ name:'Product', params:{season: order.season.toLowerCase(), year: order.year, family:order.family, product: order.productId} }">
                            <b-img thumbnail fluid :src="'https://ctworker.com/images/basket_size/' + order.productId + '.jpg'" alt="Image 1"></b-img>
                        </router-link>
                    </td>
                    <td class="basketItem">
                        <router-link class="basketItem" :to="{ name:'Product', params:{season: order.season, year: order.year, family:order.family, product: order.productId}  }">{{ order.productId }} - {{ order.description }}
                            <p>{{ order.productSize }}
                                <span v-if="order.productSize === 1 && order.sizes === 0"> mes</span>
                                <span v-else-if="order.productSize === 'unica'"> Talla única</span>
                                <span v-else-if="order.productSize === 1 && order.sizes === 1"> año</span>
                                <span v-else-if="order.sizes === 1"> años </span>
                                <span v-else> meses</span>
                            </p>
                        </router-link>
                    </td>
                    <td class="basketItem">{{ order.color_name }}</td>
                    <td class="basketItem">{{ fixNumber(order.price) }} €</td>
                    <td class="basketItem text-center">{{ order.quantity }}</td>
                    <td class="basketItem text-center">{{ fixNumber(order.price * order.quantity) }} €</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>

export default {
    name: 'OrderDetailsDesktopView',
    data(){
        return{
            orderDetails: [],  
        }
    },
    
    props:{
        orderNumber: Number,
        fixNumber: Function,  
        new_season: Number,
    },  
    methods:{
        convertToObject(orderArray){
            var arraySizes = ['unica', '00', 0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 18, 24, 36]
            var obj = []
            for(let i = 0; i < orderArray.length; i++){ 
                for(let j = 0; j < arraySizes.length; j++){
                    if(orderArray[i]['talla_' + arraySizes[j]] != 0){
                        var orderItem = {
                            productId: orderArray[i]['modelo'],
                            color: orderArray[i]['color'],
                            productSize: arraySizes[j],
                            quantity: orderArray[i]['talla_' + arraySizes[j]],
                            price: parseFloat(orderArray[i]['item_order_price']),
                            season: orderArray[i]['season'].toLowerCase(),
                            year: orderArray[i]['year'],
                            family: orderArray[i]['family'],
                            description: orderArray[i]['description'],
                            color_name: orderArray[i]['color_name'],
                            sizes: orderArray[i]['sizes']
                        }
                       obj.push(orderItem) 
                    }   
                }
            }
            return obj
        }, 
        orderDetailsInfo(){
            this.axios.get('getOrderDetails/' + this.orderNumber + '/' + this.$store.getters.username).then(response => {
                this.$root.$emit('loader', this.loader)
                this.orderDetails = this.convertToObject(response.data) 
                this.$root.$emit('getOrderedProductInfo', this.orderDetails)  
            })
            this.$root.$emit('loader', !this.loader)   
        },
    },
    created(){
        this.orderDetailsInfo()
    },   
}
</script>


