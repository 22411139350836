<template>
    <footer class="pt-3">
        <div class="social-media-icons-b mr-3">
            <b-link class="social-icon" href="https://facebook.com"><font-awesome-icon :icon="['fab', 'facebook-square']"/></b-link>
            <b-link class="social-icon" href="https://instagram.com"><font-awesome-icon :icon="['fab', 'instagram']"/></b-link>
            <b-link class="social-icon" href="https://pinterest.com"><font-awesome-icon :icon="['fab', 'pinterest']"/></b-link>
        </div> 
        <b-row class="my-2 footer-column m-0">
            <router-link class="linkTitle mb-2" :to="{ name: 'QuienSomos' }" >Información</router-link>
            <b-col>
                <router-link class="linkTitle location" :to="{ name: 'QuienSomos' }" size="10em">Localización<i class="material-icons">location_on</i></router-link>
            </b-col>
            <b-col class="xs-hidden">
                <h5 class="linkTitle mt-0">953741829<i class="material-icons">call</i></h5>
            </b-col>
        </b-row>
        <div class="footer-copyright pb-2">
            <hr class="mt-0">
            <b-navbar-nav>
                <router-link class="nav-link text-center w-50 mx-auto mb-3 font-abril-fatface" :to="{ name: 'Welcome' }">©{{ date }} Tony Bambino</router-link>
            </b-navbar-nav>
            <b-navbar-nav>
                <b-nav-text class="text-center"><router-link :to="{ name: 'Condiciones' }">Condiciones Generales</router-link> | <router-link :to="{ name: 'Cookies' }">Política de Cookies</router-link> | <router-link :to="{name: 'ProteccionDatos'}">Política de Protección de datos</router-link> </b-nav-text>
            </b-navbar-nav>
        </div>
    </footer>
</template>
<script>
    export default {
        name: 'Footer',
        computed:{
            date(){
               var d = new Date()
               return d.getFullYear();
            }
        }
    }
</script>
