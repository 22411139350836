<template>
    <header class="f-pacifico header-background" id="box">
        <div class="mb-4 text-center md-hidden lg-hidden xl-hidden">
            <router-link class="font-abril-fatface logo-xs" :to="{ name: 'Welcome' }">Tony Bambino</router-link>
            <p class="f-pacifico text-secondary">Since 1969</p>
        </div>
        <div v-if="$route.name ==='Welcome'" class="text-center bg-secondary font-abril-fatface text-light free-ship-text-div">
            <span class="free-ship-text">Envíos gratis para pedidos a partir de 300€</span>
        </div>
        <b-sidebar right  id="sidebar-backdrop" title="Buscador" backdrop shadow>
            <b-form @submit.prevent="makeSearch" class="h-100">
                <div class="px-3 py-3 text-center">
                    <b-form-group label="Introduzca la palabra que desea buscar" label-for="backdrop-variant">
                        <b-form-input id="backdrop-variant" v-model="search" required></b-form-input>
                        <p class="text-danger">{{ error }}</p>
                        <b-button type="submit" class="mt-5 mx-auto d-block btn-form" variant="primary">Buscar</b-button>
                    </b-form-group>
                </div>
            </b-form>
        </b-sidebar>
        <div :class="sticky">
            <b-navbar toggleable="lg" type="dark" variant="light" :class="bg">
                <b-navbar-toggle v-b-toggle.sidebar-backdrop2 target="#"></b-navbar-toggle>
            <b-sidebar right id="sidebar-backdrop2">
            <template>
                <router-link v-if="!isLoggedIn" :class="'user-icon-menu font-abril-fatface '" :to="{ name: 'Login' }">
                    <b-icon icon="person" font-scale="2.8"></b-icon>
                    <span class="login-xs-link-menu">Identifícate</span>
                </router-link>
                <button v-else class="user-icon-menu font-abril-fatface" @click="routerMyAccount(user)">
                    <b-icon icon="person-fill" font-scale="2.8"></b-icon>
                    <span class="'login-xs-link-menu'">Hola {{ shortenUser }}</span>
                </button>
                <div class="px-3">
                    <b-form-group>
                        <b-list-group>
                            <b-list-group-item class="list-group-item list-group-item-action"  @click="routerLink('Welcome')">Inicio</b-list-group-item>
                            <b-list-group-item v-b-toggle="'collapse-2'">Colecciones</b-list-group-item>
                                <b-collapse id="collapse-2" class="mt-0">
                                    <b-dropdown-item class="list-group-item list-group-item-action" @click="routerSeason('verano', 2024)">-Verano 2024<span class="new-season"> New</span></b-dropdown-item>
                                    <b-dropdown-item class="list-group-item list-group-item-action" @click="routerSeason('verano', 2025)">-Verano 2025<span class="new-season"> New</span></b-dropdown-item>
                                    <b-dropdown-item class="list-group-item list-group-item-action" @click="routerSeason('invierno', 2024)">-Invierno 2024/2025<span class="new-season"> New</span></b-dropdown-item>
                                </b-collapse>
                            <b-list-group-item class="list-group-item list-group-item-action" @click=" routerLink('QuienSomos')">Quiénes Somos</b-list-group-item>
                            <b-list-group-item class="list-group-item list-group-item-action" @click="routerLink('Contact')">Contacto</b-list-group-item>
                        </b-list-group>
                    </b-form-group>
                </div>
                <b-button v-if="isLoggedIn" class="mx-auto d-block btn-form" variant="primary" @click="logout()">Cerrar Sesión</b-button>
            </template>
            </b-sidebar>
            <b-collapse id="nav-collapse" class="mx-3" is-nav>
                <b-navbar-nav>
                    <router-link class="nav-link" :to="{ name: 'Welcome' }">Inicio</router-link>
                    <b-nav-item-dropdown text="Colecciones" class="dropdown-trigger dropdown-trigger1">
                        <b-dropdown-item @click="routerSeason('invierno', 2024)" class="w-100">Invierno 2024/2025<span class="new-season"> New</span></b-dropdown-item>
                        <b-dropdown-item @click="routerSeason('verano', 2025)" class="w-100">Verano 2025<span class="new-season">New</span></b-dropdown-item>
                        <b-dropdown-item @click="routerSeason('verano', 2024)" class="w-100">Verano 2024<span class="outlet"> Outlet</span></b-dropdown-item>
                    </b-nav-item-dropdown>
                    <router-link class="nav-link" :to="{ name: 'QuienSomos' }">Quiénes Somos</router-link>
                    <router-link class="nav-link" :to="{name: 'Contact'}">Contacto</router-link>
                </b-navbar-nav>
                <router-link class="xs-hidden sm-hidden d-block mx-auto" :to="{ name: 'Welcome' }">
                    <h4 class="font-abril-fatface logo-lg text-center" :to="{ name: 'Welcome' }">Tony Bambino</h4>
                </router-link>
            </b-collapse>
            <router-link class="xs-hidden sm-hidden lg-hidden xl-hidden d-block" :to="{ name: 'Welcome' }">
                <h4 class="font-abril-fatface logo-lg" :to="{ name: 'Welcome' }">Tony Bambino</h4>
            </router-link>
            <div class="xs-icons">
                <router-link v-if="!isLoggedIn" class="user-icon" :to="{ name: 'Login' }">
                    <span class="login-xs-link font-abril-fatface">Identifícate</span>
                    <b-icon icon="person" font-scale="2.8" class="user-icon-i"></b-icon>
                </router-link>
                <button v-else class="user-icon hover-green" @click="routerMyAccount(user)">
                    <span class="xs-hidden sm-hidden md-hidden font-abril-fatface login-xs-link">Hola {{ shortenUser }}</span>
                    <b-icon icon="person-fill" font-scale="2.8" class="user-icon-i"></b-icon>
                </button>
                <b-icon icon="search" class="xs-search hover-green mx-2" font-scale="1.8" v-b-toggle.sidebar-backdrop></b-icon>
                <b-link class="basket" v-model="basket" :to="{ name: 'Basket' }">
                    <v-badge color="rgb(252, 255, 255, 0.5)" v-if="!isLoggedIn || numInCart === 0" :content="'0'" overlap>
                        <b-icon icon="basket3-fill" class="mb-1"></b-icon>
                    </v-badge>
                    <v-badge color="rgb(255, 255, 205, 0.3)" v-else :content="numInCart" overlap>
                        <b-icon icon="basket3-fill" class="mb-1"></b-icon>
                    </v-badge>
                </b-link>
            </div>
            </b-navbar>
        </div>
    </header>
</template>
<script>
export default {
    name: 'Header',
    data(){
        return{
            user:null,
            isLoggedIn: localStorage.getItem('tbStore.jwt') && localStorage.getItem('tbStore.user') != null,
            search:"",
            basket:null,
            cantidad:"",
            loader:true,
            inCart:[],
            error: '',
            sticky: '',
            bg:'',
        }
    },

    created(){
        this.setDefaults()
        window.addEventListener('scroll', this.handleScroll)
        this.$root.$on('isLoggedIn', username =>{
            this.user = username
            this.isLoggedIn = true
        })

        this.$root.$on('logOut',() => {
            this.logout()
        })
        console.log(process.env.APP_ENV)
    },

    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    computed: {
        numInCart: {
            get() {
                return this.$store.getters.inCart
            },
            set() {
                return this.$store.dispatch('fetchCartItems', this.user)
            }
        },

        shortenUser() {
        if (this.user.length > 10) {
            return this.user.substring(0, 8) + '...';
        }

        return this.user;
        },

        date() {
            var d = new Date()
            var c = d.getFullYear()
            return c
        },
    },

    methods: {
        handleScroll() {
            if (window.scrollY > 200 && this.$route.name !== 'Product') {
                this.sticky = 'sticky'
                this.bg= 'bg-sticky '
            } else {
                this.sticky = ''
                this.bg = ''
            }
        },

        makeSearch() {
            this.$router.push({ name:'Searcher', params:{ search: this.search }}).catch(() => {} )
            this.$store.dispatch('makeSearch', this.search)
            this.$root.$emit('loader', true)
            let a = []
            let user = this.user
            a = JSON.parse(localStorage.getItem('busqueda')) || []
            a.push(this.search)
            localStorage.setItem('busqueda', JSON.stringify(a))
            let busqueda = JSON.parse(localStorage.getItem('busqueda'))
            this.axios.post('searchsInsert', { user, busqueda })
            this.search = ''
        },

        setDefaults() {
            if (this.isLoggedIn) {
                this.user = this.$store.getters.username
                this.$store.dispatch('checkUserAuth', this.user)
                this.$store.dispatch('fetchCartItems', this.user)
            }
        },

        change() {
            this.isLoggedIn = null
            this.$store.dispatch("doLogout", this.user)
            this.$router.push({ name: 'Welcome' }).catch(()=>{})
            this.$root.$emit('loader', false)
        },

        logout() {
            let user = this.user
            this.axios.post('logout',{ user })
                .then(this.change())
                .catch(error => {
                if (error.response.status) {
                    alert('ha ocurrido un error')
                }
            })
        },

        routerSeason(season, year) {
            const info = {
                season:season.charAt(0).toUpperCase() + season.slice(1),
                year:year
            }
            if (this.user !== null) {
                this.$root.$emit('season', info)
                this.$root.$emit('loader', true)
                this.$store.dispatch('getSeasonProducts', info)
            }
            if (season !== this.$route.name && year !== this.$route.params) {
                this.$router.push({ name:'Season',params:{ season:season, year:year }}).catch(() => {})
            }
        },

        routerMyAccount(user) {
            this.$router.push({ name:'MyAccount',params:{ user:user } }).catch(() => {})
        },

        routerLink(name) {
            this.$router.push({ name:name }).catch(() => {})
        }
    },

}
</script>

