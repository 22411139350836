<template>
    <b-container>
        <b-row class="my-5">
            <h3 class="mx-auto formTitle text-center s-title-width" v-if="season === 'Invierno'">Colección de {{ season + ' ' + year + '/' + (parseInt(year) + 1)}}</h3>
            <h3 class="mx-auto formTitle text-center s-title-width" v-else>Colección de {{  season + ' ' + year }}</h3>
        </b-row>
        <b-row class="img-gall-row">
            <b-col class="col-12 col-sm-6 col-md-4 mx-auto" center v-for="(product, index) in getProducts" :key="index">
                <router-link class="imgTitle" :to="{ name:'Family', params:{ season: season, year: year, family: product.familia }}">
                    <b-img center fluid :src="'https://ctworker.com/images/gallery_size/' + product.modelo + '.jpg'"  class="imgBorder hoverHand" :alt="'ver ' + product.familia"></b-img>
                    <h6 class="text-center mx-auto mt-2  hoverHand mb-5">{{ product.familia }}</h6>
                </router-link>
            </b-col>
            <router-link class="imgTitle my-5" :to="{ name:'Family', params:{ season: season, year: year, family: 'ver_todo' }}">
                <h3 class="mb-5 hoverHand text-center">Ver todo el catálogo</h3>
            </router-link>
        </b-row>
    </b-container>
</template>
<script>
export default {
    name: 'Season',
    data() {
        return {
            products: [],
            season: this.$route.params.season.charAt(0).toUpperCase() + this.$route.params.season.slice(1),
            year: this.$route.params.year
        }
    },

    created() {
        this.$root.$on('season', info => {
           this.season = info.season
           this.year = info.year
        })
        const info = {
            season:this.season.charAt(0).toUpperCase() + this.season.slice(1),
            year:this.year
        }
        this.$store.dispatch('getSeasonProducts', info)
        document.title = 'Colección ' + this.season + ' en Tony Bambino'
    },

    computed: {
        getProducts() {
            if(this.$store.getters.seasonProducts.length > 0) {
                this.$root.$emit('loader', false)
            }
            return this.$store.getters.seasonProducts
        }
    },

}
</script>

