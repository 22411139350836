<template>
    <div v-if="$route.name === 'Error404'">
        <Error></Error>
    </div>
    <div v-else>
        <Header></Header>
        <Loader v-if="loader"></Loader>
        <router-view :fixNumber="fixNumber"></router-view>
        <CookieLaw></CookieLaw>
        <Footer></Footer>
    </div>
</template>
<style>
@import './css/style.css';
</style>
<script>
import Header from './components/Header'
import Footer from './components/Footer'
import Loader from './components/Loader'
import Error from './components/errorComponents/404'
import CookieLaw from './components/cookiesComponents/CookieLaw'
export default {
    name: 'app',
    components: {
        Header,
        Footer,
        Loader,
        Error,
        CookieLaw
    },

    data() {
        return {
            loader:false,
        }
    },

    methods: {
        fixNumber(price) {
            return parseFloat(price).toFixed(2).toString().replace(/\./g, ',')
        }
    },

    mounted() {
        this.$root.$on('loader', loader => { this.loader = loader })
    }
}
</script>
