<template>
  <b-container fluid>
    <b-row class="my-5 xs-hidden">
			<h3 class="mx-auto formTitle c-title-width">Mi Cuenta</h3>
		</b-row>
    <b-row class="mb-3 mt-5 sm-hidden md-hidden lg-hidden xl-hidden font">
			<h5 class="mx-auto">Hola {{ this.$store.getters.username }}</h5>
		</b-row>
    <div>
      <b-card-group class="font account-container mx-auto" deck>
        <router-link :to="{ name:sec.href }" v-for="(sec, section) in sections" :key="section" class="mx-auto account-card">
          <b-card :title="sec.title" :img-src="'/img/' + sec.icon" img-left class="p-3 card-inside xs-hidden">
            <b-card-text class="text-secondary">{{ sec.description }}</b-card-text> 
          </b-card>
          <b-card :img-src="'/img/' + sec.icon"  img-top class="card-inside sm-hidden md-hidden lg-hidden xl-hidden">
            <p >{{sec.title}}</p>
          </b-card>
        </router-link>
      </b-card-group>
      <b-button class="mx-auto d-block btn-form mt-4 mb-5" variant="primary" @click="logout()">Cerrar Sesión</b-button> 
    </div>
  </b-container>
</template>
<script>
export default {
  name: 'MyAccount',
  data() {
    return {
      sections: [
        { title:'Direcciones', description:'Accede a tus direcciones de envío y facturación', href:'Adresses', icon:'adresses_icon.png' },
        { title:'Pedidos',  description:'Ver el estado de tus pedidos y los detalles', href:'Orders', icon:'orders_icon.png' },
        { title:'Mi Cuenta', description:'Editar inicio de sesión, email, contraseña', href:'MyAccountDetails', icon:'user_icon.png' },
      ]
    } 
  },
  methods:{
    logout(){
      this.$root.$emit('loader', true)
      this.$root.$emit('logOut')
    }
  },
  created(){
    document.title = 'Mi Cuenta - ' + this.$store.getters.username
  }
}
</script>

