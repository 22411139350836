<template>
    <div>
      <router-link class="link text-center" :to="{name: url , params: { user: this.$store.getters.username }}">{{ message }}</router-link>
    </div>
</template>
<script>

export default {
  name: 'UserLink',
  props:{
    message: String,
    url: String,
  },  
}
</script>
<style>
  .link{
    font-family: 'Pacifico';
    color: #596161;
    display: flex;
    align-items: center;
    display: block;
    text-decoration: none  
  }
</style>
