
export function createProductsObject(arrayData){
    let sizes = ['unica', '00', 0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 18, 24, 36]
    let obj = []
    if(arrayData.length > 0){
        for(var i = 0; i < arrayData.length; i++) {
            for(var j = 0; j < sizes.length; j++) {
                if(arrayData[i]['talla_' + sizes[j]] != 0) {
                    var objectInfo = {
                        modelo: arrayData[i]['modelo'],
                        id_color: arrayData[i]['color'],
                        color: arrayData[i]['color_name'],
                        new: arrayData[i]['new'],
                        talla: sizes[j],
                        cantidad: arrayData[i]['talla_' + sizes[j]],
                        precio: parseFloat(arrayData[i]['price_in_cart']),
                        season: arrayData[i]['season'].toLowerCase(),
                        id: arrayData[i]['modelo'] + arrayData[i]['color'].trim()  + arrayData[i]['color_name'].trim() + sizes[j],
                        year: arrayData[i]['year'],
                        family: arrayData[i]['family'],
                        description: arrayData[i]['description'],
                        sizes: arrayData[i]['sizes']
                    }
                obj.push(objectInfo)
                }
            }
        }

        return obj
    }
    return false
}