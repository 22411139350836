<template>
    <b-container>
        <h3 class="mx-auto text-center formTitle my-5 qs-title-width">Quiénes Somos</h3>
            <p class="text-center formText qs-text-width mx-auto mt-5">
                Somos una empresa española de ropa para bebé. Confeccionamos nuestras prendas con todo el amor y cariño que los más peques de la casa se merecen.
                Nuestra ropa está confeccionada para niñas y niños con edades que van desde los recien nacidos hasta los cuatro años de edad.
                Creemos firmemente en nuestra marca, por eso elaboramos nuestras prendas con el máximo cariño y la mayor dedicación que tenemos.
                Puedes localizarnos en la carretera Úbeda-Baeza en el km 3,5, cerca de la localidad de Úbeda, en la provincia de Jaén
                Puedes contactar con nosotros a través del formulario de la sección de <router-link :to="{name: 'Contact'}">contacto</router-link> o a través de nuestro teléfono 953741829.
            </p>
        <b-row>
            <b-col>
                <div class="my-5">
                    <iframe class="mx-auto map d-block formTitle" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3143.5110429272668!2d-3.42344868431345!3d38.011863979716885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd6e8b55117ba4cb%3A0x4c6a5663acb40c33!2sCreaciones+Tony!5e0!3m2!1ses!2ses!4v1531302548386"  allowfullscreen></iframe>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
    export default {
        name: 'QuienSomos',
        created(){
            document.title = 'Quiénes Somos'
        }

    }
</script>

