<template>
    <div>
        <b-media class="border-top border-bottom basketItem my-2 mx-1" v-for="(order, Index) in orderDetails" :key="Index">
            <b-row class="mt-3 orderDetails">
                <b-col class="ml-4 mx-auto">
                    <div class="productImage ml-4">
                        <router-link :to="{  name:'Product', params:{season: order.season.toLowerCase(), year: order.year, family:order.family, product: order.productId} }">
                            <b-img thumbnail fluid :src="'https://ctworker.com/images/basket_size/' + order.productId + '.jpg'" alt="Image 1"></b-img>
                        </router-link>
                    </div>
                </b-col>
                <b-col class="ml-4 mx-auto">
                    <router-link :to="{ name:'Product', params:{ season: order.season, year: order.year, family:order.family, product: order.productId }}" class="mb-0">Mod. {{ order.productId }}<p>{{order.description}}</p></router-link>
                    <p class="mb-0">Talla : <span class="text-secondary"> {{order.productSize}} </span>
                        <span class="text-secondary" v-if="order.productSize === 1 && order.sizes === 0"> mes</span>
                        <span class="text-secondary" v-else-if="order.productSize === 'unica'"> Talla única</span>
                        <span class="text-secondary" v-else-if="order.productSize === 1 && order.sizes === 1"> año</span>
                        <span class="text-secondary" v-else-if="order.sizes === 1"> años </span>
                        <span class="text-secondary" v-else> meses</span>
                    </p>
                    <p class="mb-0">Color : <span class="text-secondary">{{ order.color_name }}</span></p>
                    <p class="mb-0">Cant : <span class="text-secondary">{{ order.quantity }}</span></p>
                    <p class="mb-3">Precio : <span class="text-secondary">{{ fixNumber(order.price) }} €</span></p>
                    <p>Subtotal : <span class="text-danger">{{ fixNumber(order.price * order.quantity) }} €</span></p>
                </b-col>
            </b-row>
        </b-media>
    </div>
</template>
<script>
export default {
    name: 'OrderDetailsMobileView',
    data(){
        return{
            orderDetails: [], 
        }
    },
    
    props:{
        orderNumber: Number,
        fixNumber: Function, 
    },  

    mounted(){
        this.$root.$on('getOrderedProductInfo', obj => { this.orderDetails = obj })
        this.$root.$on('billingAdressCountry', country =>{ this.country = country })
    },  
}
</script>
<style sccoped>
    @media (min-width:0px) and (max-width: 575px) {
        .img-thumbnail{
            max-width: 145px;
        }
    }
    @media (min-width:576px) and (max-width: 767px) {
        .productImage{
            margin-left:10%
        }
       .orderDetails{
           margin-left: 15%!important;
       }
    }
</style>

