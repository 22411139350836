<template>
  <b-container class="mt-5">
    <b-card class="mt-3 mb-5 mr-3 mx-auto col-12 pasword-card-width">
      <ValidationObserver ref="email">
        <b-form @submit.prevent="sendEmail">
            <h4 class="text-center mb-5">Recuperar Contraseña</h4>
            <ValidationProvider rules="required|email|espacios" name="email">
              <div class="input-field mt-5" slot-scope="{ errors, valid }">
                <label for="email">email</label>
                <b-form-input type="email" id="email" :state="errors[0] ?  false : (valid ? true : null)" v-model="email" ></b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </div>
            </ValidationProvider>
            <p class="pt-3 text-center">*Introduzca su cuenta de email para recibir un mensaje de cambio de contraseña</p>
            <b-overlay id="overlay-background" :show="show" class="overlay overlay-prec-width mx-auto" rounded="sm">
              <b-button type="submit" class="my-5 btn-form d-block" variant="primary">Verificar email</b-button>
            </b-overlay>
        </b-form>
      </ValidationObserver>
      <b-alert variant="success" class="alert mx-auto" :show="dismissCountDown" @dismissed="dismissCountDown = 0">Se ha enviado un mensaje a este email para recuperar su contraseña</b-alert>
      <b-alert variant="danger" class="alert" :show="dismissCountDown2"  @dismissed="dismissCountDown2 = 0">{{ errors }}</b-alert>
    </b-card>
  </b-container>
</template>
<script>
export default {
  name: 'ForgotPassword',

  data() {
    return {
      email:'',
      token: this.$route.params.token,
      dismissSecs: 8,
      dismissCountDown: 0,
      dismissCountDown2: 0,
      errors:'',
      show: false,
      counter: 0
    }
  },

  methods:{

    async sendEmail() {
      const success = await this.$refs.email.validate();
      if (!success) {
        return false
      }

      if (this.counter === 1) {
        this.errors = 'Hemos enviado ya un email para reactivar su contraseña, por favor consulte su cuenta de correo electrónico'
        this.dismissCountDown2 = this.dismissSecs
        return false
      }

      this.show = true
      let email = this.email
      let brand = 'Tony Bambino'
      let senderEmail = 'xcreacionestonyx@gmail.com'
      this.axios.post('submitForgetPasswordForm',{ email, brand, senderEmail })
        .then(()=>{
          this.dismissCountDown = this.dismissSecs
          this.show = false
          this.counter = 1
        }).catch(error => {
          if (error.response.status === 422) {
            this.errors = 'El email introducido no existe'
            this.dismissCountDown2 = this.dismissSecs
          }

          if (error.response.status === 500) {
            this.errors = 'Ha ocurrido un error, por favor recarrge la página'
            this.dismissCountDown2 = this.dismissSecs
          }

          this.show = false
        })
    }
  },

  created() {
    document.title = 'Recuperación de Contraseña'
  }
}
</script>
<style>
  .overlay-prec-width {
    max-width: 173px
  }
  .pasword-card-width {
    max-width: 450px
  }
</style>



