<template>
    <b-container>
        <b-card class="da-card-width mx-auto">
            <ValidationObserver  ref="observer">
                <b-form refs="form" @submit.prevent="setShippingAdress">
                    <h4 class="text-center session-title">Dirección de Envío</h4>
                    <b-row class="mb-0 mt-5">
                        <ValidationProvider rules="required" class="col-12 col-md-6">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="nombre" v-model="form.name" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="nombre">Nombre</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required" class="col-12 col-md-6">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="apellidos" v-model="form.surname" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="apellidos">Apellidos</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </b-row>
                    <b-row class="mb-0">
                        <ValidationProvider  rules="required|vat" class="col-12 col-md-3">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="cif" v-model="form.cif" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="cif">DNI/CIF</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required" class="col-12 col-md-9">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="empresa" v-model="form.business_name" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="empresa">Empresa</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </b-row>
                    <ValidationProvider rules="required" class="col-12 col-md-3">
                        <div class="input-field mt-0" slot-scope="{ valid, errors }">
                            <b-form-input id="direccion" v-model="form.adress" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                            <label for="direccion">Dirección</label>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                    <b-row class="mb-0">
                        <ValidationProvider rules="required" class="col-12 col-md-3">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="cp" v-model="form.postal_code" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="cp">Código Postal</label>
                                    <div class="validation_error text-danger">{{ errors[0] }}</div>

                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required" class="col-12 col-md-9">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="poblacion" v-model="form.city" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="poblacion">Población</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </b-row>
                    <b-row class="mb-0">
                        <ValidationProvider rules="required" class="col-12 col-md-6">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="provincia" v-model="form.region" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="provincia">Provincia</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider class="col-12 col-md-6 pb-0" rules="required">
                            <div class="input-field mt-0" slot-scope="{ errors }">
                                <label for="country" class="active">País</label>
                                <select name="country" id="country" class="form-control" v-model="selectedCountry" :state="errors[0] ? false :true">
                                    <b-form-select-option value="">Seleccione su país</b-form-select-option>
                                    <b-form-select-option :value="country.name_es"  v-for="(country, Index) in countriesList" :key="Index" >{{ country.name_es }}</b-form-select-option>
                                </select>
                                <span class="pl-2 text-danger">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </b-row>
                    <b-row class="mb-0">
                        <ValidationProvider rules="required|email" class="col-12 col-md-9">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="email" v-model="form.email" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="email">email</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required|numeric" class="col-12 col-md-3">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="telefono" v-model="form.telephone" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="telefono">Teléfono</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </b-row>
                    <b-overlay id="overlay-background" class="mx-auto d-block adr-overlay-width" :show="show" rounded="sm">
                        <b-button type="submit" class="mx-auto d-block btn-form my-4" variant="primary">Agregar</b-button>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
            <transition name="fade">
                <b-alert variant="success" class="mb-4" :show="dismissCountDown" @dismissed="dismissCountDown=0">Dirección agregada</b-alert>
            </transition>
            <b-alert variant="danger" class="mb-4" :show="dismissCountDown2"  @dismissed="dismissCountDown2=0">{{errors}}</b-alert>
        </b-card>
        <UserParamLink :message="'Volver a Mis Direcciones'" :url="'Adresses'" class="my-5"></UserParamLink>
    </b-container>
</template>
<script>
import UserParamLink from "../buttons/UserParamLink"
export default {
    name: 'ShippingAdressForm',
    components: {
        UserParamLink
    },
    data(){
        return{
            userInfo:[],
            dismissSecs: 8,
            dismissCountDown: 0,
            dismissCountDown2: 0,
            errors:'',
            countriesList: [],
            selectedCountry: '',
            show: false,
            counter: 0,
            form:{
                name:'',
                surname:'',
                cif:'',
                business_name:'',
                adress:'',
                postal_code:'',
                city:'',
                region:'',
                country:'',
                email:'',
            }
        }
    }, 
    created(){
        this.axios.get('getInfoByUser/' + this.$store.getters.username).then(response => {this.userInfo = response.data})  
        this.axios.get('getAllCountries').then(response => {this.countriesList = response.data})
    },
    methods: {
        async setShippingAdress(){
            if(this.counter >= 1){
                this.errors = 'Acaba de ingresar una dirección de envío, borre la dirección ingresada o modifíquela'
                this.dismissCountDown2 = this.dismissSecs
                return false
            }
            this.counter ++
            this.show = true
            const success = await this.$refs.observer.validate()
            if (!success) {       
                this.show = false
                this.errors = '¡Su formulario tiene errores!'
                this.dismissCountDown2 = this.dismissSecs
                return false
            }
            this.errors = ''
            const formInfo = 
            {
                nombre: this.form.name,
                apellidos: this.form.surname,
                empresa: this.form.business_name,
                direccion: this.form.adress,
                cp: this.form.postal_code,
                provincia: this.form.region,
                ciudad: this.form.city,
                email: this.form.email,
                telefono: this.form.telephone,
                cif: this.form.cif,
                usuario:this.$store.getters.username,
                num_cliente: this.userInfo[0]['num_cliente'],
                pais: this.selectedCountry
            }
            this.axios.post('setShippingAdressInfo', { formInfo }).then(()=>{
                this.dismissCountDown = this.dismissSecs
                this.show = false
            }).catch(error=> {
                if(error.response.status){
                    this.dismissCountDown2 = this.dismissSecs
                    this.errors = 'Ha habido un error! Por favor recargue la página'
                }
                this.show = false
            })
        },
    },
}
</script>

