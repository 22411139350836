<template>
    <b-container v-if="this.$route.params.family === 'ver_todo'" class="my-5">
        <b-row class="my-5">
            <h3 class="mx-auto formTitle text-center s-title-width">Todos los Artículos</h3>
        </b-row>
        <b-row class="img-gall-row">
            <b-col  class="col-12 col-sm-6 col-md-4 my-5 mx-auto" v-for="(product, index) in allProducts" :key="index">
                <router-link class="imgTitle" :to="{ name:'Product', params:{ season: season, year: year, family: family, product: product.modelo }}">
                    <b-img center thumbnail fluid :src="'https://ctworker.com/images/gallery_size/' + product.modelo + '.jpg'" class="imgBorder hoverHand" width="300" height="auto"></b-img>
                    <p class="text-center mt-3 mb-0">{{ product.tipo + ' (Mod.' + product.modelo + ')' }}</p>
                    <p class="text-center">{{ product.precio_rebaja > 0 && product.new === 0 ? product.precio_rebaja.replace(/\./g, ',') + '€' : product.precio.replace(/\./g, ',') + '€' }}</p>
                </router-link>
            </b-col>
        </b-row>
    </b-container>
    <b-container v-else>
        <b-row class="my-4">
            <h3 class="mx-auto formTitle text-center qs-title-width">{{ family }}</h3>
        </b-row>
        <b-row class="img-gall-row my-5">
            <b-col class="col-12 col-sm-6 col-md-4 mx-auto" v-for="(product, index) in familia" :key="index">
                <router-link class="imgTitle" :to="{ name:'Product', params:{ season: product.temporada, year: product.año, family:product.familia, product: product.modelo }}">
                    <b-img center thumbnail fluid :src="'https://ctworker.com/images/gallery_size/' + product.modelo + '.jpg'" class="imgBorder hoverHand"></b-img>
                    <p class="text-center mt-3 mb-0">{{ product.tipo + ' (Mod.' + product.modelo + ')' }}</p>
                    <p class="text-center mb-1" v-if="product.precio_rebaja > 0 && product.new === 0"><del class="text-danger">{{  product.precio.replace(/\./g, ',') + '€' }}</del></p>
                    <p class="text-center">{{ product.precio_rebaja > 0 && product.new === 0 ? product.precio_rebaja.replace(/\./g, ',') + '€' : product.precio.replace(/\./g, ',') + '€' }}</p>
                </router-link>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
     name: 'Family',
        data(){
            return {
                allProducts:[],
                familia : [],
                family: this.$route.params.family,
                season:this.$route.params.season,
                year: this.$route.params.year
            }
        },
        created(){
            this.$root.$emit('loader', true)
            this.family = this.family == 'ver_todo' ? 'Ver Todo ' : this.family

            if (this.$route.params.family !== 'ver_todo') {
                this.axios.get('getProductsByFamily/' + this.season + '/' + this.year + '/' + this.family + '/tony').then(response => {
                    if(response.status === 200){
                        this.$root.$emit('loader', false)
                        this.familia = response.data
                    }
                })// call only a family from a season
            }

            this.axios.get('getProductsBySeason/' + this.season + '/' + this.year + '/tony').then(response => {
                if(response.status === 200){
                    this.allProducts = response.data
                    this.$root.$emit('loader', false)
                }
            }) //call all products from a season

            document.title = this.family + ' de ' + this.season
        },
    }
</script>
