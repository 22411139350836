<template>
    <div class="mx-auto">
        <b-card class="mx-3 session-form login-form-heigth">
            <ValidationObserver ref="login">
                <b-form @submit.prevent="loginSubmit">
                    <h3 class="text-center session-title">Iniciar Sesión</h3>
                    <div class="my-auto d-block mx-3">
                        <ValidationProvider rules="required|email|espacios" name="email">
                            <div class="input-field mt-4" slot-scope="{ valid, errors }">
                                <label for="email">email</label>
                                <b-form-input type="email" id="email" :state="errors[0] ? false : (valid ? true : null)" v-model="email" ></b-form-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider vid="password" name="password" rules="required">
                            <div class="input-field mt-5" slot-scope="{ valid, errors }">
                                <label for="password">Contraseña</label>
                                <b-form-input id="password" :state="errors[0] ? false : (valid ? true : null)" v-model="password" type="password" autocomplete="on"></b-form-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </div>
                    <b-button type="submit" class="my-5 mx-auto d-block btn-form" variant="primary">Iniciar Sesión</b-button>
                    <p class="text-center">
                        <label>
                            <input type="checkbox" v-model="status" name="checkbox-1" />
                            <span>Recuérdame</span>
                        </label>
                    </p>
                </b-form>
            </ValidationObserver>
            <p class="text-center mt-4 mx-2">
                *Al identificarte aceptas nuestras <router-link :to="{ name:'Condiciones' }"> Condiciones generales</router-link> de uso y venta. Consulta nuestra
                <router-link :to="{ name:'ProteccionDatos' }">  Política de Privacidad</router-link> y  nuestro<router-link :to="{ name:'Cookies' }"> Política de Cookies</router-link>.
            </p>
            <p class="text-center pt-3">
                <router-link class="text-center mx-auto mt-3" :to="{ name:'ForgotPassword' }">¿Has olvidado tu contraseña?</router-link>
            </p>
        </b-card>
        <div>
            <p class="text-center">¿eres nuevo en Tony Bambino?</p>
            <router-link :to="{ name:'Register' }"><b-button class="mb-5 mx-auto d-block font-abril-fatface bg-warning" variant="primary">Registrarse en Tony Bambino</b-button></router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Login',
    data() {
      return {
        email: "",
        password: "",
        status: false
      }
    },
    created() {
        document.title = 'Iniciar Sesión en Tony Bambino'
    },
    
    methods: {
        async loginSubmit() {
            const success = await this.$refs.login.validate();
            if (!success) {
                return false
            }
            this.$root.$emit('loader', true)
            if (this.password.length > 0) {
                let email = this.email
                let password = this.password
                let status = this.status

                this.axios.post('login', { email, password, status })
                    .then(response => {
                        this.$store.dispatch('setToken', response.data.token)
                        this.$store.dispatch('setUsername', response.data.username)
                        this.$store.dispatch('fetchCartItems', response.data.username)
                        this.$store.dispatch('checkUserAuth', response.data.username)
                        this.$root.$emit('isLoggedIn',response.data.username)
                        localStorage.setItem('tbStore.jwt', JSON.stringify(response.data.token))
                        localStorage.setItem('tbStore.user', JSON.stringify(response.data.username))
                        localStorage.setItem('state.Auth', true)
                        this.$root.$emit('loader', false)
                        this.$router.push({ name: 'Welcome' })
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            this.$refs.login.setErrors({
                                password: ['Usuario no permitido en esta marca']
                            })
                        }
                        if (error.response.status === 403) {
                            this.$refs.login.setErrors({
                                password: ['La cuenta no ha sido verificada, por favor revise su email']
                            })
                        }
                        if (error.response.status === 404) {
                            this.$refs.login.setErrors({
                                username: ['El email introducido no existe. Por favor, registre una cuenta con su número de cliente y su email']
                            })
                        }
                        if (error.response.status === 500) {
                            this.$refs.login.setErrors({
                                password: ['La contraseña o email introducidos no son correctos'],
                            })
                        }
                        this.$root.$emit('loader', false)
                    })
            }
        },
    }
}
</script>

