<template>
    <b-container class="p-0 welcome-div" fluid>
        <div class="mb-1 mt-1 xs-hidden sm-hidden">
            <agile :autoplay="true" :autoplaySpeed="6000" @after-change="e => currentSlide = e.currentSlide">
                <div  class="slide-agile-img hoverDrag h-100">
                    <h5 slot="caption" class="sliderTitleSummer" @click="routerSeason('verano', 2024)">
                        {{ captions[currentSlide] }}
                    </h5>
                    <b-img src="img/verano_2022_slider.jpg" fluid alt="Responsive image" class="hoverDrag"></b-img>
                </div>
                <div class="slide-agile-img hoverDrag h-100">
                    <h5 slot="caption" class="sliderTitleWinter" @click="routerSeason('invierno', 2023)">
                        {{ captions[currentSlide] }}
                    </h5>
                    <b-img src="img/invierno_2022_slider.jpg" fluid alt="Responsive image" class="hoverDrag"></b-img>
                </div>
                <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
                <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
            </agile>
        </div>
        <b-row class="w-100 mx-0">
            <b-col cols="12" sm="4" class="p-0 prod mt-3 md-hidden lg-hidden xl-hidden">
                <h6 class="w-text font-abril-fatface f-white">Invierno 2023/24</h6>
                <router-link :to="{name: 'Season', params:{ season: 'invierno', year: '2023'}}">
                    <b-img center class="hoverHand" fluid src="img/algodon_oso.jpg"  alt="Image 2"></b-img>
                </router-link>
            </b-col>
            <b-col cols="12" sm="4" class="p-0 prod">
                <h6 class="w-text font-abril-fatface f-pink">Primavera/Verano 2024</h6>
                <router-link :to="{name: 'Season', params:{ season: 'verano', year: '2024'}}">
                    <b-img center class="hoverHand" fluid src="img/verano_2022.jpg"  alt="Image 2"></b-img>
                </router-link>
            </b-col>
            <b-col cols="12" sm="4" class="p-0 prod-background b-crude">
                <h6 class="w-text font-abril-fatface f-yellow">Outlet Verano</h6>
                <router-link :to="{name: 'Season', params:{ season: 'verano', year: '2023'}}">
                    <b-img center class="hoverHand" fluid src="img/outlet_verano.jpg"  alt="Image 2"></b-img>
                </router-link>
            </b-col>
            <b-col cols="12" sm="4" class="p-0 prod">
                <h6 class="w-text font-abril-fatface f-pink">Conjuntos</h6>
                <router-link :to="{ name:'Family', params:{ season: 'verano', year: 2024, family: 'Conjuntos' }}">
                    <b-img center class="hoverHand" fluid src="img/conjuntos.jpg"  alt="Image 2"></b-img>
                </router-link>
            </b-col>
              <b-col cols="12" sm="4" class="p-0 prod-background b-green">
                <h6 class="w-text font-abril-fatface f-yellow">Punto</h6>
                <router-link :to="{ name:'Family', params:{ season: 'verano', year: 2024, family: 'Punto' }}">
                    <b-img center class="hoverHand" fluid src="img/punto.jpg"  alt="Image 2"></b-img>
                </router-link>
            </b-col>
            <b-col cols="12" sm="4" class="p-0 prod">
                <h6 class="w-text font-abril-fatface f-pink">Accesorios</h6>
                <router-link :to="{ name:'Family', params:{ season: 'invierno', year: 2023, family: 'Accesorios' }}">
                    <b-img center class="hoverHand" fluid src="img/accesorios.jpg"  alt="Image 2"></b-img>
                </router-link>
            </b-col>
            <b-col cols="12" sm="4" class="p-0 prod-background b-pink">
                <h6 class="w-text font-abril-fatface  f-white">Patucos</h6>
                <router-link :to="{ name:'Family', params:{ season: 'invierno', year: 2023, family: 'Accesorios' }}">
                    <b-img center class="hoverHand" fluid src="img/patucos.jpg"  alt="Image 2"></b-img>
                </router-link>
            </b-col>
            <b-col cols="12" sm="4" class="p-0 prod xs-hidden sm-hidden">
                <h6 class="w-text font-abril-fatface f-white">Algodón</h6>
                <router-link :to="{ name:'Family', params:{ season: 'invierno', year: 2023, family: 'Algodón' }}">
                    <b-img center class="hoverHand" fluid src="img/algodon_oso.jpg"  alt="Image 2"></b-img>
                </router-link>
            </b-col>
            <b-col cols="12" sm="4" class="p-0 prod-background b-yellow">
                <h6 class="w-text font-abril-fatface f-yellow">Pijamas</h6>
                <router-link :to="{ name:'Family', params:{ season: 'invierno', year: 2023, family: 'Pijamas y Muditas' }}">
                    <b-img center class="hoverHand" fluid src="img/pijama.jpg"  alt="Image 2"></b-img>
                </router-link>
            </b-col>
            <b-col cols="12" sm="4" class="p-0 prod">
                <h6 class="w-text font-abril-fatface f-yellow">Buzos</h6>
                <router-link :to="{ name:'Family', params:{ season: 'verano', year: 2024, family: 'Buzos' }}">
                    <b-img center class="hoverHand" fluid src="img/buzos.jpg"  alt="Image 2"></b-img>
                </router-link>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
    name: 'Welcome',
    data(){
        return{
            currentSlide: 0,
            captions: [
                'Primavera-Verano 2024',
                'Otoño-Invierno 2023/2024',
            ]
        }
    },
    created(){
        document.title = 'Tony Bambino'
    },
    methods:{
        routerSeason(season, year){
            const info={
                season:season.charAt(0).toUpperCase() + season.slice(1),
                year:year
            }
            if(this.user !== null){
                this.$root.$emit('season', info)
                this.$root.$emit('loader', true)
                this.$store.dispatch('getSeasonProducts', info)
            }

            if(season !== this.$route.name && year !== this.$route.params ){
                this.$router.push({name:'Season',params:{ season:season, year:year }}).catch(()=>{})
            }
        },
    },
}
</script>

