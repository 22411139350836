<template>
    <b-container class="error404Body text-center">
        <span class="numer">4</span>
        <div class="circle ">
            <div class="drops"></div>
            <div class="drops"></div>
            <div class="hand"></div>
            <div class="hand rgt"></div>
            <div class="holder">
            <div class="bob">
                <div class="nose"></div>
                <div class="face">
                    <div class="mouth">
                        <div class="tongue"></div>
                    </div>
                </div>
                <div class="ear"></div>
                <div class="ear rgt"></div>
                <div class="neck"></div>
                </div>
            </div>
        </div>
        <span class="numer">4</span>
        <div>
            <h1 class="errorText mt-0 error404 text-center">404</h1>
            <h1 class="errorText mt-0 text-center">Error</h1>
        </div>
        <div>
            <router-link :to="{name: 'Welcome'}" class="linkColor">Volver a tonyBambino.com</router-link>
        </div>
    </b-container>
</template>
<script>
export default {
    name: 'Error404',
    created(){
        document.title = 'Tony Bambino'
    }
}
</script>
<style>
    @import '../../css/error404.css';
</style>
