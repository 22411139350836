<template>
    <b-container class="bv-example-row bv-example-row-flex-cols" fluid>
      <b-row class="mb-5 mt-2">
        <h3 class="mx-auto formTitle text-center mt-5 ad-title-width">Mis Direcciones</h3>
      </b-row>
      <b-card bg-variant="light" header="Direcciones de Envío" class="font mx-auto col-12 col-sm-11 col-md-8 col-lg-6 ad-card-width">
        <ShippingAdressSelector></ShippingAdressSelector>
      </b-card>
      <b-card bg-variant="light" header="Direcciones de Facturación" class="mx-auto font col-12 col-sm-11 col-md-8 col-lg-6 ad-card-width">
        <BillingAdressSelector></BillingAdressSelector>
      </b-card>
      <UserParamLink class="my-5" :message="'Volver a Mi Cuenta'" :url="'MyAccount'"></UserParamLink>
    </b-container>
</template>
<script>
import ShippingAdressSelector from "./ShippingAdressSelector"
import BillingAdressSelector from "./BillingAdressSelector"
import UserParamLink from "../buttons/UserParamLink"
export default {
  name: 'Adresses',
  data(){
    return{
      url: window.location.href.indexOf('#')
    }
  },
  components: {
    ShippingAdressSelector,
    BillingAdressSelector,
    UserParamLink
  },
 
}
</script>


