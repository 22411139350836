<template>
    <b-container>
        <b-row>
			<h3 class="notAllowed text-center">Su cuenta ya ha sido verificada. Puede acceder a todos los contenidos de nuestra web.&#128522;</h3>
		</b-row>
    </b-container>
</template>
<script>
import { decryptString } from '../encryption/encryption'

export default {
    name: 'Verified',

    data() {
        return{
            user: decryptString(this.$route.params.user, 50),
            token: decryptString(this.$route.params.token, 2)
        }
    },
    
    created() {
        if (this.token && this.user !== null) {
            localStorage.setItem('tbStore.jwt', JSON.stringify(this.token))
            localStorage.setItem('tbStore.user', JSON.stringify(this.user))
            sessionStorage.setItem('check.user', JSON.stringify(this.user))
            sessionStorage.setItem('check.jwt', this.token)
            this.$store.dispatch('setToken', this.token)
            this.$store.dispatch("setUsername", this.user)
            this.$store.dispatch('checkUserAuth', this.user)
            this.$store.dispatch('fetchCartItems', this.user)
            this.$root.$emit('isLoggedIn', this.user)
        }

        this.checkUser()
        document.title = 'Cuenta verificada en Tony Bambino'
    },

    methods: {
        checkUser() {
            if (sessionStorage.getItem('check.jwt') !== JSON.parse(localStorage.getItem('tbStore.jwt')) &&
                sessionStorage.getItem('check.user') !== JSON.parse(localStorage.getItem('tbStore.user'))) {
                this.$router.push({ name:'error404' })
            }
        },
    },
}
</script>