<template>
  <div>
    <div v-if="this.shippingAdress.length === 0">
      <h6 class="text-center abril-fatface my-5"> {{message}}</h6>
      <hr>
    </div>
    <div v-else>
      <div v-for="(info, Index) in shippingAdress" :key="Index">
        <tr>
          <td class="mt-4 card-label adr-td-width">
            <label class="w-100">
              <input type="radio" class="filled-in" :id="info.id" v-model="picked" :value="info.id" @click="setSelectedShippingAdress(info.id, info.num_cliente)"/>
              <span>
                <p class="abril-fatface">{{ info.nombre + ' ' + info.apellidos }}</p>
                <p class="text-dark">{{ info.direccion + ', ' + info.codigo_postal + ', ' + info.ciudad + ' (' + info.provincia + ')' }}</p>
              </span> 
            </label>
          </td>
          <button v-if="$route.name === 'Adresses'" class="delete-button mb-4" @click="deleteShippingAdress(info.id)">
            <i class="material-icons">delete</i>
          </button>
          <router-link :to="{name: 'ShippingAdressFormFilled', params:{user: info.usuario, id: info.id}}">
            <b-button class="grayButton deleteButton">editar</b-button>
          </router-link>
        </tr> 
        <hr>
      </div>
    </div>
    <router-link class="float-right" :to="{name: 'ShippingAdressForm'}">
      <b-button class="grayButton mt-4 mb-2">Agregar  dirección</b-button>
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'shippingAdressSelector',
  data() {
    return {
      shippingAdress: [],
      picked: '',
      message:'Cargando direcciones...',
    }
  },
  created(){
    this.getShippingAdress()
  },
   computed:{
    checkShippingAdress(){
      let shippingAdress = this.shippingAdress.find(x=> x.selected === 1)
      if(shippingAdress){
        return shippingAdress['id']
      }
      return false
    }
  },
  watch:{
    checkShippingAdress:{
      handler: function (newVal) {
        this.picked = newVal
      }
    }
  },
  methods:{
    setSelectedShippingAdress(id, customer){
      this.axios.post('setSelectedShippingAdress', { id, customer }).then(() => {
        this.$root.$emit('adressInfo', this.axios.get('getShippingAdressInfo/' + this.$store.getters.username))
      })
    },
    getShippingAdress(){
      this.axios.get('getShippingAdressInfo/' + this.$store.getters.username).then(response=> { 
        if(response.data.length === 0){
          this.message = 'No tiene ninguna dirección de envío. Por favor, agregue una.'
        }
        this.shippingAdress = response.data
      })
    },
    deleteShippingAdress(id){
      this.axios.delete('deleteShippingAdress/' + id).then(()=>{
        let adress = this.shippingAdress.map(adress => {
          if(adress.id === id){
            return this.shippingAdress.indexOf(adress.id)
          }
        })
        this.shippingAdress.splice(adress, 1)
        this.shippingAdress.find(adress => adress.selected === 0)
        this.getShippingAdress()
      })
    }
  },
}
</script>
<style>
  .grayButton{
    float:right!important;
  }

  .card-header{
    text-align: center
  }

  .card-label{
    margin-left: 5%
  }

  @media (min-width:1800px){
    .ad-card-width{
      width:800px!important
    }
  }

  @media (min-width:0px) and (max-width: 575px) {
    .card-label{
      margin-left: 1%
    }
    .deleteButton{
      margin-bottom:30px
    }
  }

</style>