import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createProductsObject } from './user/shoppingCart.js'
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)
Vue.use(VueAxios, axios)

require('./headers/headers')

export default new Vuex.Store({

    state: {
        username: JSON.parse(localStorage.getItem('tbStore.user')),
        auth: localStorage.getItem('state.Auth'),
        token: JSON.parse(localStorage.getItem('tbStore.jwt')),
        inCart: [],
        search:[],
        seasonProducts: [],
        inCartItems:[],
    },

    getters: {
        inCart: state => state.inCart,
        username: state=> state.username,
        auth: state => state.auth,
        search: state => state.search,
        seasonProducts: state => state.seasonProducts,
        token: state => state.token,
        inCartItems: state => state.inCartItems,
    },

    mutations: {

        /*--------Login------*/

        DO_LOGOUT(state) {
            state.auth = localStorage.removeItem('state.Auth')
            state.username = null
            state.token = null
        },

        /*-------ShoppingCart---------*/

        FETCH_CART_ITEMS(state, inCartItems) {
            state.inCartItems = inCartItems;
        },

        FETCH_CART_ITEMS_NUMBER(state, inCart) {
            state.inCart = inCart;
        },

        ADD_TO_CART(state, quantity) {
            state.inCart = state.inCart + quantity
        },

        REMOVE_FROM_CART(state, quantity) {
            state.inCart = state.inCart - quantity
        },

        EMPTY_ITEM_IN_CART(state, item) {
            state.inCart = state.inCart - item
        },

        /*-------Search---------*/

        MAKE_SEARCH(state, search) {
            state.search = search
        },

        /*----------Product---------*/

        GET_SEASON_PRODUCTS(state, products) {
            state.seasonProducts = products
        },

        /*------------User--------*/

        CHECK_USER_AUTH(state) {
            state.auth = true
        },

        SET_USERNAME(state, username) {
            state.username = username
        },

        SET_TOKEN(state, token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
            state.token = token
        }

    },

    actions: {

        doLogout({ commit }, username) {
            localStorage.removeItem('tbStore.jwt')
            localStorage.removeItem('tbStore.user')
            localStorage.removeItem('order_number')
            localStorage.removeItem('busqueda')
            commit('DO_LOGOUT', username)
        },

        addToCart({ commit }, quantity) {
            var q = quantity
            commit('ADD_TO_CART', q)
        },

        removeFromCart({ commit }, quantity) {
            var q = quantity
            commit('REMOVE_FROM_CART', q)
        },

        emptyItemInCart({ commit }, item) {
            var i = item
            commit('EMPTY_ITEM_IN_CART', i)
        },

        setToken({commit}, token) {
            commit('SET_TOKEN', token)

        },

        setUsername({commit}, username) {
            commit('SET_USERNAME', username)
        },

        fetchCartItems({ commit }, username) {
            if (username !== null) {
                return axios.get('getItemsFromCartByUser/' + username).then(response => {
                    var quantity = 0
                    var shoppingCart = []
                    if (response.data.length > 0) {
                        shoppingCart = createProductsObject(response.data)
                        quantity = shoppingCart.map((item) => {
                            return item.cantidad
                        }).reduce((total,num) => {
                            return total + num
                        })
                    }
                    commit('FETCH_CART_ITEMS_NUMBER', quantity)
                    commit('FETCH_CART_ITEMS', shoppingCart)
                })
            }
        },

        makeSearch({ commit }, search) {
            axios.get('getProductFromSearch/' + search + '/tony').then(response => {
                commit('MAKE_SEARCH', response.data)
            }).catch(error => {
                if(error.response.status === 401) {
                    commit('DO_LOGOUT')
                }
            })
        },

        getSeasonProducts({ commit }, info) {
            if (JSON.parse(localStorage.getItem('tbStore.user')) && JSON.parse(localStorage.getItem('tbStore.jwt')) != null) {

                axios.get('getFamiliesFromProducts/' + info.season + '/' + info.year + '/tony').then(response => {

                    commit('GET_SEASON_PRODUCTS', response.data)
                    return response.data

                }).catch(error =>{
                    if(error.response.status === 401) {
                        commit('DO_LOGOUT')
                        this.$router.push({ name:'welcome' })
                    }
                })
            }
        },

        checkUserAuth({ commit }, user) {
            axios.post('user',{ user }).then(() => {
                commit('CHECK_USER_AUTH')
                console.log('user verified')
            }).catch(error => {
                if(error.response.status) {
                    localStorage.clear()
                }
            })
        }
    },

    //plugins: [createPersistedState({ paths: ['username', 'token'] })],
    //plugin de vuex para que el array con los items de la cesta persista al refrescar la página
});