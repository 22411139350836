import Welcome from "./components/Welcome.vue"
import Login from "./components/sessionComponents/Login.vue"
import Register from "./components/sessionComponents/Register.vue"
import Contact from "./components/Contact.vue"
import QuienSomos from "./components/QuienSomos.vue"
import Season from "./components/Season.vue"
import Family from "./components/Family.vue"
import Product from "./components/Product.vue"
import ProteccionDatos from "./components/ProteccionDatos.vue"
import Condiciones from "./components/Condiciones.vue"
import Cookies from "./components/Cookies.vue"
import Searcher from "./components/Searcher.vue"
import Basket from "./components/Basket.vue"
import Error404 from "./components/errorComponents/404.vue"
import MyAccount from "./components/myAccountComponents/MyAccount.vue"
import Registered from "./components/Registered.vue"
import NotAllowed from "./components/errorComponents/NotAllowed.vue"
import WrongCollection from "./components/errorComponents/WrongCollection.vue"
import Verified from "./components/Verified.vue"
import SuccessPayment from "./components/SuccessPayment.vue"
import SuccessOrder from "./components/SuccessOrder.vue"
import Adresses from "./components/adressComponents/Adresses.vue"
import MyAccountDetails from "./components/myAccountComponents/MyAccountDetails.vue"
import ShippingAdressFormFilled from "./components/adressComponents/ShippingAdressFormFilled.vue"
import BillingAdressFormFilled from "./components/adressComponents/BillingAdressFormFilled.vue"
import ShippingAdressForm from "./components/adressComponents/ShippingAdressForm.vue"
import BillingAdressForm from "./components/adressComponents/BillingAdressForm.vue"
import Orders from "./components/ordersComponents/Orders.vue"
import OrderDetails from "./components/ordersComponents/OrderDetails.vue"
import PasswordChangeForm from "./components/myAccountComponents/PasswordChangeForm.vue"
import PasswordChanged from "./components/myAccountComponents/PasswordChanged.vue"
import ForgotPassword from "./components/passwordResetComponents/ForgotPassword.vue"
import PasswordRecoveryForm from "./components/passwordResetComponents/PasswordRecoveryForm.vue"
import PasswordRecoverySuccess from "./components/passwordResetComponents/PasswordRecoverySuccess.vue"

var year = 2025
var season = 'invierno'

export default[

    // dynamic segments start with a colon
    { path: '/', name: 'Welcome', component: Welcome },
    { path: '/condiciones', name: 'Condiciones', component: Condiciones },
    { path: '/cookies', name: 'Cookies', component: Cookies },
    { path: '/registrado',   name: 'Registered', component: Registered },
    { path: '/proteccion_de_datos', name: 'ProteccionDatos', component: ProteccionDatos },
    { path: '/login', name: 'Login', component: Login},
    { path: '/registro', name: 'Register', component: Register},
    { path: '/contacto', name: 'Contact', component: Contact },
    { path: '/quien_somos', name: 'QuienSomos', component: QuienSomos },
    { path: '/cesta', meta: { requiresAuth: true }, name: 'Basket', component: Basket },
    { path: '/no_permitido', name: 'NotAllowed', component: NotAllowed},
    { path: '/wrong_collection', name: 'WrongCollection', component: WrongCollection},
    { path: '/agregar_direccion_envio', meta: { requiresAuth: true }, name: 'ShippingAdressForm', component: ShippingAdressForm },
    { path: '/agregar_direccion_facturacion', meta: { requiresAuth: true }, name: 'BillingAdressForm', component: BillingAdressForm },
    { path: '/forgot_password', name: 'ForgotPassword', component: ForgotPassword },
    { path: '/password_changed/:token', meta: { requiresAuth: true }, name: 'PasswordRecoverySuccess', component: PasswordRecoverySuccess },
    { path: '/password_recovery/:token/:encrypt', name: 'PasswordRecoveryForm', component: PasswordRecoveryForm},
    { path: '/success_payment/:session_id',meta: { requiresAuth: true }, name: 'SuccessPayment', component: SuccessPayment },
    { path: '/success_order/:session_id', meta: { requiresAuth: true }, name: 'SuccessOrder', component: SuccessOrder },
    { path: '/miCuenta/:user/password_changed', meta: { requiresAuth: true }, name: 'PasswordChanged', component: PasswordChanged },
    { path: '/miCuenta/detalles/:user', meta: { requiresAuth: true }, name: 'MyAccountDetails', component: MyAccountDetails },
    { path: '/miCuenta/cambiar_password/:user/:token', meta: { requiresAuth: true }, name: 'PasswordChangeForm', component: PasswordChangeForm },
    { path: '/verified/:token/:user', name: 'Verified', component: Verified },
    { path: '/miCuenta/direcciones/:user', meta: { requiresAuth: true }, name: 'Adresses', component: Adresses},
    { path: '/miCuenta/pedidos/:user', meta: { requiresAuth: true }, name: 'Orders', component: Orders},
    { path: '/direccion_de_envio/:user/:id', meta: { requiresAuth: true }, name: 'ShippingAdressFormFilled', component: ShippingAdressFormFilled },
    { path: '/miCuenta/pedido/:orderNumber/:user', meta: { requiresAuth: true }, name: 'OrderDetails', component: OrderDetails },
    { path: '/direccion_de_facturacion/:user/:id', meta: { requiresAuth: true }, name: 'BillingAdressFormFilled', component: BillingAdressFormFilled },
    { path: '/miCuenta/:user', name: 'MyAccount', meta: { requiresAuth: true }, component: MyAccount },
    { path: '/result/:search', name: 'Searcher', meta: { requiresAuth: true }, component: Searcher },
    { path: '/:season/:year', name: 'Season', meta: { requiresAuth: true, year: year, season: season },  component: Season },
    { path: '/:season/:year/:family', name: 'Family', meta: { requiresAuth: true , year: year, season: season}, component: Family },
    { path: '/:season/:year/:family/:product', meta: { requiresAuth: true , year: year, season: season}, name: 'Product', component: Product },
    { path: '/:pathmatch(.*)*', name: 'Error404', component: Error404 },
]
