<template>
    <b-container>
        <b-row class="px-3">
            <h3 class="mx-auto text-center formTitle mt-5">Política de Privacidad. Protección de Datos de Carácter General.</h3>
        </b-row>
        <b-row>
            <b-col class="formText mx-3 mb-5 mt-2">
                <p class="my-3">La presente Política de Privacidad (en adelante, la “POLÍTICA DE PRIVACIDAD”) regula, con carácter general, la recogida y tratamiento por parte de José Higueras Pulpillo y Hermano C.B.
                (en adelante, “Tony Bambino”) de los datos personales que usted nos facilita como usuario de la página web accesible a través del nombre de dominio https://www.tonybambino.com/
                (en adelante, el “WEBSITE”), incluidos los servicios de blog, redes sociales, servicios de información “Newsletter” o cualquiera otras funcionalidades que en cada momento se encuentren disponibles a través del WEBSITE.</p>
                <p class="my-3">Todo ello sin perjuicio de las previsiones específicas establecidas en determinadas secciones, formularios o servicios disponibles en el WEBSITE al objeto de facilitarle la información pertinente y, en su caso, recabar
                su consentimiento.</p>
                <p class="my-3">Tony bambino se reserva el derecho a modificar la POLÍTICA DE PRIVACIDAD para adaptarla a novedades legislativas, jurisprudenciales y/o administrativas, así como a las prácticas
                que desarrolle en cada momento a través del WEBSITE, teniendo en todo momento en cuenta sus derechos e intereses.</p>
                <p class="my-3">En todo caso Tony bambino le proporcionará los recursos técnicos adecuados para que pueda acceder a la POLÍTICA DE PRIVACIDAD en aras a dar debido cumplimiento a las obligaciones de información que nos
                corresponde observar de conformidad con la normativa aplicable en materia de protección de datos.</p>
                <h5 class="formText mt-5 mx-3 text-dark">1. ¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DE SUS DATOS?</h5>
                <p class="my-3">El responsable del Tratamiento de sus datos es Tony bambino, entidad con domicilio en Úbeda (Jaén), Carretera Úbeda-Baeza, Km 3’5, inscrita en la sección y con C.I.F. número E-23032949.</p>
                <p class="my-3">Para cualquier cuestión relacionada con la POLÍTICA DE PRIVACIDAD y/o el tratamiento de sus datos personales puede contactar con nosotros en la dirección postal antes indicada y/o a través de la dirección de correo electrónico confeccionespopys@gmail.com.</p>
                <h5 class="formText mt-5 mx-3 text-dark">2. ¿CÓMO HEMOS OBTENIDO SUS DATOS?</h5>
                <p class="my-3">En el caso de que los datos personales aportados pertenecieran a un tercero, usted garantiza que ha informado a dicho tercero de la POLÍTICA DE PRIVACIDAD y ha obtenido su autorización para facilitar sus datos a Tony bambino con las finalidades que se señalan más adelante.</p>
                <p class="my-3">Adicionalmente le informamos del posible tratamiento de sus datos de redes sociales a través de los perfiles corporativos que Tony bambino mantiene disponible en cada red social en la que se encuentra presente, todo ello en los términos y condiciones establecidos en cada red social.</p>
                <h5 class="formText mt-5 mx-3 text-dark">3. ¿QUÉ TIPO DE DATOS TRATAMOS?</h5>
                <p class="my-3">Los datos que tratamos en Tony bambino son aquellos que usted nos ha proporcionado a través de los oportunos formularios que empleamos para formalizar nuestro contacto con usted y/o a través de correo electrónico, así como aquéllos otros que, en su caso, obtengamos a través de los perfiles corporativos de Tony bambino en las redes sociales en las que estamos presentes.</p>
                <p class="my-3">En particular en Tony bambino tratamos las siguientes categorías de datos, en función del uso que haga del WEBSITE:</p>
                <p class="my-3">- Datos identificativos y de contacto (e.g. nombre, apellidos, D.N.I., dirección postal y electrónica, teléfono, etc.).</p>
                <p class="my-3">- Códigos y claves de identificación como usuario registrado del WEBSITE.</p>
                <p class="my-3">- Datos de navegación en Internet (e.g. dirección IP, visitas a páginas web, conexiones a redes wifi, etc.).</p>
                <h5 class="formText mt-5 mx-3 text-dark">4. ¿CON QUÉ FINALIDAD TRATAMOS SUS DATOS Y BAJO QUÉ LEGITIMACIÓN?</h5>
                <p class="my-3">El tratamiento de sus datos personales persigue las siguientes finalidades:</p>
                <p class="my-3">- Gestionar y tramitar su registro como usuario, cuando éste sea posible, así como su acceso a los contenidos y servicios disponibles en el WEBISTE.</p>
                <p class="my-3">- Tramitar y gestionar la solicitud de información, sugerencias quejas o reclamaciones que realice a través del WEBSITE o del correo electrónico.</p>
                <p class="my-3">- Gestionar su suscripción a nuestro servicio “newsletter” y el envío de las correspondientes comunicaciones electrónicas, siempre que usted lo hubiera consentido</p>
                <p class="my-3">El tratamiento de sus datos personales por parte de Tony bambino, conforme a la normativa aplicable, se basa en su consentimiento, otorgado en el momento de la captación de sus datos personales que usted nos facilita voluntariamente a través de los mecanismos habilitados al efecto en el WEBSITE.</p>
                <p class="my-3">Le recordamos que, en cualquier momento, puede revocar su consentimiento libremente y de forma gratuita, en los términos que se indican en el Apartado 7 siguiente.</p>
                <h5 class="formText mt-5 mx-3 text-dark">5. ¿A QUIÉN COMUNICAMOS SUS DATOS?</h5>
                <p class="my-3">Al margen de aquéllos supuestos en los que Tony bambino esté obligado a ello por imperativo legal (e.g. Administraciones Tributarias, Administración de Justicia u otras Administraciones Públicas),
                sus datos no serán objeto de comunicación a terceras entidades.</p>
                <h5 class="formText mt-5 mx-3 text-dark">6. ¿POR CUÁNTO TIEMPO CONSERVAREMOS SUS DATOS?</h5>
                <p class="my-3">Con carácter general, los datos personales serán conservados en tanto usted no revoque su consentimiento al tratamiento o solicite su supresión, así como el tiempo necesario para cumplir las obligaciones legales que Tony bambino debe observar.</p>
                <p class="my-3">En todo caso, le informamos de que Tony bambino tiene establecidas políticas internas de depuración de datos destinadas a controlar los plazos de conservación de los datos personales que obren en su poder,
                por lo que éstos podrán ser cancelados cuando dejen de ser necesarios y/o adecuados para la finalidad para la que hubieran sido recabados.</p>
                <h5 class="formText mt-5 mx-3 text-dark">7. ¿CUÁLES SON SUS DERECHOS?</h5>
                <p class="my-3">La normativa aplicable en materia de protección de datos le otorga una serie de derechos relativos a sus datos personales que usted podrá ejercitar durante el tratamiento de los mismos.
                Dichos derechos son los que se le indican a continuación:</p>
                <p class="my-3">- Acceso a sus datos: tiene derecho a acceder a sus datos para conocer qué datos personales que le conciernen estamos tratando.</p>
                <p class="my-3">- Solicitar la limitación del tratamiento de sus datos: en determinadas circunstancias, tiene derecho a solicitarnos la limitación del tratamiento de sus datos, en cuyo caso le informamos que únicamente los conservaremos para el ejercicio o defensa de reclamaciones tal y como prevé la normativa aplicable en materia de protección de datos.</p>
                <p class="my-3">- A la portabilidad de sus datos: en determinadas circunstancias, tiene derecho a recibir los datos personales que le incumban, y que nos haya facilitado, en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a otro responsable del tratamiento.</p>
                <p class="my-3">- Oponerse al tratamiento de sus datos: en determinadas circunstancias y por motivos relacionados con su situación particular, tiene derecho a oponerse al tratamiento de sus datos en cuyo caso, dejaríamos de tratarlos salvo que, por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones, éstos deban ser conservados.</p>
                <p class="my-3">Asimismo, tiene derecho a retirar el consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.</p>
                <p class="my-3">Podrá ejercer dichos derechos mediante solicitud escrita dirigida a Tony bambino, en la dirección postal indicada en el Apartado 1.</p>
                <p class="my-3">Finalmente indicarle que puede interponer una reclamación ante la Autoridad de Control competente (en España, la Agencia Española de Protección de Datos), especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos. Puede ponerse en contacto con dicha Autoridad a través de su página web: <b-link href="https://www.agpd.es" target="_blank">www.agpd.es</b-link>.</p>
                <h5 class="formText mt-5 mx-3 text-dark">8. MEDIDAS DE SEGURIDAD</h5>
                <p class="my-3">Tony bambino tratará sus datos en todo momento de forma absolutamente confidencial y guardando el preceptivo deber de secreto respecto de los mismos, de conformidad con lo previsto en la normativa de aplicación en materia de protección de datos, adoptando al efecto las medidas de índole técnica y organizativas necesarias que garanticen la seguridad de sus datos
                y eviten su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están expuestos.</p>
                <h5 class="formText mt-5 mx-3 text-dark">9. POLÍTICA DE COOKIES</h5>
                <p class="my-3">Adicionalmente, le indicamos que Tony bambino tiene establecida una Política de Cookies que se encuentra accesible a través del siguiente<router-link :to="{name: 'Cookies'}"> enlace</router-link>.</p>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
    name: 'ProteccionDatos',
    created(){
        document.title = 'Protección de datos'
    }
}
</script>
