<template>
    <div>
        <b-card :title="'Dirección de ' + adressType" class="font" v-for="(adress, index) in adressInfo" :key="index">
            <h6 class="font">{{ adress.name + ' ' + adress.surname }}</h6>
            <h6 class="font">{{ adress.adress }}</h6>
            <h6 class="font">{{ adress.city + ' (' + adress.region + ')' }}</h6>
            <h6 class="font">{{ adress.postal_code }}</h6>
        </b-card>
    </div>
</template>
<script>
export default {
    name: 'AdressCard',
    props:{
        function: String,
        orderNumber: Number,
        adressType: String,
    },
    data() {
        return{
            adressInfo: [],
        }
    },
    created() {
        this.axios.get(this.function + '/' + this.orderNumber + '/' + this.$store.getters.username).then(response => {
            this.adressInfo = response.data
            if(this.function === 'getOrderBillingAdressByOrderNumber'){
                this.$root.$emit('billingAdressCountry', response.data[0]['country'])
            }
        })
    },
}
</script>
