<template>
  <div>
    <input class="file-input" type="file" id="file" ref="file" @change="onFileSelected($event)"/>
    <b-img id="popover" 
      v-bind="mainProps" 
      :src="imgRoute  + (file !== null ? file : defaultImg)" 
      rounded="circle">
    </b-img>
    <b-popover v-if=" file === null" show target="popover">
      Inserte la imagen arrastrando o haciendo click aquí
    </b-popover>
    <b-popover variant="danger" v-if="errorMessage" show target="popover">
      Error al insertar la imagen, recarge la página
    </b-popover>
  </div>
</template>
<script>
export default {
  name: 'FormInput',
  data() {
    return {
      file: this.value,
      mainProps: { width: this.width, height: this.height },
      files: [],
      errorMessage: false
    }
  },
  props:{
    width: Number,
    height: Number,
    imgRoute: String,
    defaultImg: String,
    value: String
  },
  
  methods:{
    onFileSelected() {
      this.files = this.$refs.file.files[0]
      let formData = new FormData()
      formData.append("my-file", this.files)
      formData.append('user', this.$store.getters.username)
      this.axios.post('setUsersAvatarImg', formData).then((response) => {
        this.file = response.data
      }).catch((error)=>{
        if (error.response) {
          this.errorMessage = true
        }
      })
    }
  },
}
</script>
<style scooped>
.file-input{
  position:absolute!important;
  width: 150px;
  height: 150px;
  cursor: pointer!important;
  opacity: 0
}
  
.popover-body{
  width: 200px!important
}

@media (min-width: 0px) and (max-width: 575px) {
  .popover-body{
    width: 100px!important
  }
}
</style>