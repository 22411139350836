<template>
    <b-container class="font-abril-fatface text-center mt-3">
		<h4 class="m-5">Su pedido se ha realizado.</h4>
        <b-card class="col-10 col-sm-6 col-md-6 col-lg-4 col-xl-3 mx-auto text-left pt-0" bg-variant="light" v-for="(info, orderDetails) in order_info" :key="orderDetails">
            <h6><span class="text-dark mr-1">Nº de Pedido: </span><span class="font"> 8300-{{ info.num_pedido }}</span></h6>
            <h6><span class="text-dark mr-1">Fecha del pedido:</span><span class="font">{{ info.fecha | moment('D [de] MMM[.] Y') }}</span></h6>
            <h6>Estado:
                <span class="ml-2 text-info"> Pedido realizado</span>
            </h6>
            <h6>Envio:
                <span class="font ml-1" v-if="parseFloat(info.portes) === 1.11">Por Determinar</span>
                <span class="font ml-1" v-else>{{ fixNumber(info.portes) }} €</span>
            </h6>
            <h6>Total productos: <span class="font">{{ fixNumber(info.precio_pedido) }} €</span></h6>
            <h6>Total productos + Envío:
                <span class="text-danger ml-1" v-if="parseFloat(info.portes) === 1.11">Por determinar </span>
                <span class="text-danger ml-1" v-else>{{ total(info.portes, info.precio_pedido) }} €</span>
            </h6>
            <b-alert show variant="info" class="mt-4 mb-0 mx-auto" v-if ="parseFloat(info.portes) === 1.11" >*Los gastos de envío están por determinar. Le enviaremos un email con la cantidad total y la forma de pago cuando los gastos de envío se procesen.</b-alert>
            <b-alert show variant="success" class="mt-4 mb-0 text-center mx-auto" v-if="info.new_season === 1">*Su pedido es de nueva temporada. Se informará de la disponibilidad de su pedido más adelante.</b-alert>
        </b-card>
        <b-media class="border-top border-bottom my-2 col-11 col-sm-7 col-md-7 col-lg-4 col-xl-3 mx-auto font">
            <b-row v-for="(details, Index) in order_details.slice(0, 1)" :key="Index" class="my-3">
                <b-col class="w-50">
                    <b-img
                        thumbnail
                        fluid width="120"
                        height="auto"
                        :src="'https://ctworker.com/images/gallery_size/' + details.modelo + '.jpg'"
                        alt="Image 1"
                    >
                    </b-img>
                </b-col>
                <b-col class="w-50">
                    <p> {{ details.description }}</p>
                    <p class="pt-0">{{ details.color_name }}</p>
                    <p class="pt-0">...</p>
                </b-col>
            </b-row>
            <b-row>
                <router-link
                    class="text-info my-3"
                    :to="{ name: 'OrderDetails', params:{ user: this.$store.getters.username, orderNumber: orderNumber }}"
                >
                    Ver los detalles de mi pedido
                </router-link>
            </b-row>
        </b-media>
        <h6 class="m-5">Hemos enviado un email a su correo electrónico con los detalles del pedido</h6>
    </b-container>
</template>
<script>
import { getCookie } from '../cookies.js'
export default {
    name: 'SuccessOrder',

    props:{
        fixNumber: Function
    },

    data() {
        return {
            session_id: this.$route.params.session_id,
            orderNumber: JSON.parse(localStorage.getItem('order_number')),
            order_success: getCookie('ord_scss'),
            order_details: [],
            order_info: [],
        }
    },
    created(){
        this.$root.$emit('loader', true)
        this.setOrderDetails()
        this.$store.dispatch('fetchCartItems', this.$store.getters.username)
        if(this.order_success === null){
            this.$router.push({ name:'Error404' })
        }
        this.axios.get('getOrderDetails/' + this.orderNumber + '/' + this.$store.getters.username ).then(response => (this.order_details = response.data))
        this.axios.get('getOrderInfo/' + this.orderNumber ).then(response => (this.order_info = response.data))
        document.title = 'Pedido Realizado'
    },
    mounted(){
        this.$root.$emit('loader', false)
    },
    methods:{
        total(productsCost, shippingCost){
            var totalOrder = parseFloat(productsCost) + parseFloat(shippingCost)
            return this.fixNumber(totalOrder)
        },
        setOrderDetails(){
            var user = this.$store.getters.username
            var orderNumber = this.orderNumber

            this.axios.post('setOrderDetails',{ user }).then(() => {
                this.axios.put('setIfOrderIsSucceeded', { orderNumber })
                this.$store.dispatch('fetchCartItems', user)

            }).catch(error => {
                if (error.response) {
                    this.$router.push({ name:'Error404' })
                }
            })
        },
    },
}
</script>
