<template>
    <b-container class="font-abril-fatface text-center mt-3">
		<h4 class="m-5">Su pago se ha procesado correctamente.</h4>
        <h6 class="m-5">Hemos enviado un email a su correo electrónico con los detalles del pedido</h6>
        <b-card class="col-10 col-sm-6 col-md-6 col-lg-4 col-xl-3 mx-auto text-left pt-0" bg-variant="light" v-for="(info, orderDetails) in order_info" :key="orderDetails">
            <h6><span class="text-dark mr-1">Nº de Pedido: </span><span class="font"> 8300-{{ info.num_pedido }}</span></h6>
            <h6><span class="text-dark mr-1">Fecha del pedido:</span> <span class="font">{{ info.fecha | moment('D [de] MMM[.] Y') }}</span></h6>
            <h6><span class="text-dark mr-1">Estado :</span>
                <span class="text-success"> Pedido realizado</span>
            </h6>
            <h6>Envio: <span class="font">{{ fixNumber(info.portes) }}€</span></h6>
            <h6>Total: <span class="font">{{ fixNumber(info.precio_pedido) }}€</span></h6>
            <h6>Total + Envío: <span class="text-danger">{{ fixNumber(info.precio_pedido + info.portes) }} € </span></h6>
        </b-card>
        <b-media class="border-top border-bottom my-2 col-11 col-sm-7 col-md-7 col-lg-4 col-xl-3 mx-auto">
            <b-row v-for="(details, Index) in order_details.slice(0, 1)" :key="Index" class="mt-3">
                <b-col class="w-50">
                    <b-img thumbnail fluid width="120" height="auto" :src="'https://ctworker.com/images/gallery_size/' + details.modelo + '.jpg'" alt="Image 1"></b-img>
                </b-col>
                <b-col class="w-50">
                    <p> {{ details.description }}</p>
                    <p class="pt-0">{{ details.color_name }}</p>
                    <p class="pt-0">...</p>
                </b-col>
            </b-row>
            <b-col>
                <router-link class="mt-3" :to="{ name: 'OrderDetails', params:{ user: this.$store.getters.username, orderNumber: orderNumber }}">Ver los detalles de mi pedido</router-link>
            </b-col>
        </b-media>
    </b-container>
</template>
<script>
import { getCookie, setCookie } from '../cookies.js'
export default {
    name: 'SuccessPayment',
    data(){
        return{
            session_id: this.$route.params.session_id,
            orderNumber: JSON.parse(localStorage.getItem('order_number')),
            order_details: [],
            order_info: []
        }
    },

    props:{
        fixNumber: Function
    },

    created() {
        this.$root.$emit('loader', true)
        this.setPaymentToken()
        this.axios.get('getOrderDetails/' + this.orderNumber + '/' + this.$store.getters.username).then(response => (this.order_details = response.data))
        this.axios.get('getOrderInfo/' + this.orderNumber ).then(response => (this.order_info = response.data))
        this.$store.dispatch('fetchCartItems', this.$store.getters.username)
        this.setOrderDetails()
        this.setIfOrderIsPaid()
        document.title = 'Pedido Realizado'
    },

    mounted() {
        this.$root.$emit('loader', false)
    },

    methods: {
        setPaymentToken() {
            let orderNumber = this.orderNumber
            let token = this.session_id

            this.axios.get('getPaymentInfo/' + orderNumber ).then(response => {
                let data = response.data

                if (data[0]['token'] === null) {
                    this.axios.post('setPaymentToken',{ token, orderNumber }).then(() => {
                        setCookie('pmt_scss', token, 0.01)
                    })
                } else if (this.orderNumber === null || getCookie('pmt_scss') === null || this.session_id != getCookie('pmt_scss')) {
                    this.$router.push({ name:'Error404' })
                }
                if (data[0]['token'] === token && getCookie('pmt_scss') === null) {
                    localStorage.removeItem('order_number')
                }
            })
        },

        setOrderDetails() {
            let user = this.$store.getters.username

            this.axios.post('setOrderDetails',{ user }).then(() => {
                this.$store.dispatch('fetchCartItems', user)
            }).catch(error => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            })
        },

        setIfOrderIsPaid() {
            let orderNumber = this.orderNumber
            this.axios.put('setIfOrderIsPaid', { orderNumber }).then(response => {
                if (response.status === 200 ) {
                    this.axios.delete('deleteUserUnpaidOrders/' + this.$store.getters.username )
                }
            })
        }
    },
}
</script>