<template>
    <b-container>
        <b-row class="mt-3 mb-2">
			<h3 class="mx-auto formTitle text-center s-title-width">Detalles del Pedido</h3>
		</b-row>
        <b-row bg-variant="light" class="mx-auto or-adr-width">
            <AdressCard class="col-sm-6 col-md-6" :function="'getOrderShippingAdressByOrderNumber'" :orderNumber="parseInt(this.$route.params.orderNumber)" :adressType="'Envío'"></AdressCard>
            <AdressCard class="col-sm-6 col-md-6" :function="'getOrderBillingAdressByOrderNumber'" :orderNumber="parseInt(this.$route.params.orderNumber)" :adressType="'Facturación'"></AdressCard>
        </b-row>
     5       <b-card bg-variant="light" class="mx-auto or-width"  v-for="(info, orderDetails) in orderInfo" :key="orderDetails">
                <h5 v-if="info.new_season === 1" class="text-info text-center font-abril-fatface">Nueva Temporada</h5>
                <b-card class="mx-auto p-0 font or-card-width">
                    <h6 class="text-dark">Fecha del pedido :
                        <span class="font"> {{ info.fecha | moment('D [de] MMM[.] Y') }}</span>
                    </h6>
                    <h6 class="text-dark">Nº de Pedido :
                        <span class="font"> 8300-{{ info.num_pedido }}</span>
                    </h6>
                    <h6 class="text-dark">Estado :
                        <span v-if="info.estado === 0 || info.estado === 5" class="text-info">Preparándose</span>
                        <span v-else-if="info.estado === 1" class="text-info">Enviado</span>
                        <span v-else-if="info.estado === 2" class="text-success">Recibido</span>
                        <span v-else-if="info.estado === 3" class="text-danger">Cancelado</span>
                    </h6>
                    <h6 class="text-dark">Nº de envío :
                        <span v-if="info.num_envio === ''" class="font">No disponible</span>
                        <span v-else class="font">{{ info.num_envio }}</span>
                    </h6>
                </b-card>
                <OrderDetailsDesktopView class="d-none d-sm-block" :fixNumber="fixNumber" :orderNumber="parseInt(info.num_pedido)"></OrderDetailsDesktopView>
                <OrderDetailsMobileView class="d-block d-sm-none" :fixNumber="fixNumber" :orderNumber="parseInt(info.num_pedido)"></OrderDetailsMobileView>
                <b-alert show variant="info" class="mt-5 w-100 text-left pl-3" v-if="info.new_season === 1">*Su pedido es de nueva temporada. Se informará de la disponibilidad de su pedido más adelante.</b-alert>
                <b-alert show variant="info" class="w-100 text-left" v-if="info.portes === '1.11'" >*Los gastos de envío están por determinar, debido al país de su dirección de envío. Le enviaremos un email con la cantidad total y la forma de pago cuando los gastos de envío se procesen.</b-alert>
                <b-card bg-variant="light" class="total-info">
                    <h5 class="abril-fatface my-1 total-info-text">Productos
                        <span v-if="country === 'España'" > + IVA</span>
                        <span v-if="surcharge === 1"> + R.E</span> :
                        <span>{{ fixNumber(parseFloat(info.precio_pedido)) }} €</span>
                    </h5>
                    <div v-if="info.portes !== '1.11'">
                        <h5 class="abril-fatface my-1 total-info-text mr-1">Envío:
                            <span>{{ fixNumber(parseFloat(info.portes)) }} €</span>
                        </h5>
                        <h5 class="abril-fatface my-1 total-info-text">Total pedido :
                            <span class="text-danger ml-3">{{ fixNumber(parseFloat(info.precio_pedido) + parseFloat(info.portes)) }} €</span>
                        </h5>
                    </div>
                    <div v-else>
                        <h5 class="abril-fatface my-3 total-info-text">Envío: Por determinar</h5>
                    </div>
                </b-card>
            </b-card>
        <UserParamLink class="my-5" :message="'Volver a Mis Pedidos'" :url="'Orders'"></UserParamLink>
    </b-container>
</template>
<script>
import OrderDetailsDesktopView from "./OrderDetailsDesktopView"
import OrderDetailsMobileView from "./OrderDetailsMobileView"
import AdressCard from "../adressComponents/AdressCard"
import UserParamLink from '../buttons/UserParamLink'
export default {
    name: 'OrderDetails',
    components: {
        OrderDetailsDesktopView,
        OrderDetailsMobileView,
        AdressCard,
        UserParamLink
    },
    props:{
        fixNumber:Function
    },
    data(){
        return{
            orderInfo: [],
            total:Number,
            country: '',
            surcharge: Number
        }
    },
    created(){
        this.$root.$emit('loader', this.loader)
        this.$root.$on('billingAdressCountry', country =>{ this.country = country })
        this.axios.get('getUserInfo/' + this.$store.getters.username).then((response)=> (this.surcharge = response.data[0]['recargo']))
        this.axios.get('getOrderInfoByOrderNumber/' + this.$route.params.orderNumber + '/' + this.$store.getters.username).then(response => (this.orderInfo = response.data))
        this.$root.$emit('loader', !this.loader)
        document.title = this.$store.getters.username + ' - Pedido nº' + this.$route.params.orderNumber
    },
}
</script>
<style scoped>
.or-card-width{
    width: 350px
}
.total-info{
    float: right;
    text-align: right;
}

@media (min-width: 0px) and (max-width:575px){
    .or-card-width{
        width: 300px
    }
    .or-adr-width{
        width:340px
    }
    .total-info-text{
        font-size: 1.2rem
    }
}
@media (min-width: 576px) and (max-width:768px){
    .total-info-text{
        font-size: 1.3rem
    }
}
@media (min-width: 769px) and (max-width:991px){

    .total-info-text{
        font-size: 1.4rem
    }

}
@media (min-width: 992px) and (max-width: 1250px){
    .or-width, .or-adr-width, .alert-pos{
        width: 900px
    }
}

@media (min-width: 1200px){
    .or-width, .or-adr-width, .alert-pos{
        width: 1000px
    }
}

</style>

