<template>
    <b-container>
      <h4 class="notAllowed text-center">No puedes navegar por nuestra web si no estas registrado. Para registrarte llama al 953741829 y hazte cliente.</h4>
    </b-container>
</template>
<script>
export default {
  name: 'NotAllowed',
  created(){
    this.$root.$emit('loader', false)
    document.title = 'Tony Bambino'
  }
}
</script>