<template>
  <b-container class="mt-5">
    <b-card class="mt-3 mb-5 mr-3 mx-auto col-12 pasword-card-width">
      <ValidationObserver ref="passwordRecovery">
        <b-form  @submit.prevent="changePassword">
          <h3 class="text-center mb-5">Cambiar de Contraseña</h3>
          <ValidationProvider rules="required|minmax:8,contraseña,caracteres, La|espacios" name="password">
            <div class="input-field" slot-scope="{ valid, errors }">
              <label for="password">Nueva Contraseña</label>
              <b-form-input id="password" ref="password" aria-describedby="password-help-block" :state="errors[0] ? false : (valid ? true : null)" v-model="password" type="password"></b-form-input>
              <b-form-invalid-feedback class="mb-5">{{ errors[0]}}</b-form-invalid-feedback>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required|minmax:8,contraseña,caracteres, La|espacios" name="repeatPass">
            <div class="input-field mt-4"  slot-scope="{ valid, errors }">
              <label for="repeatPass">Repetir Contraseña</label>
              <b-form-input id="repeatPass" aria-describedby="password-help-block" :state="errors[0] ? false : (valid ? true : null)" v-model="repeatPass" type="password"></b-form-input>
              <b-form-invalid-feedback class="mb-5">{{ errors[0] }}</b-form-invalid-feedback>
            </div>
          </ValidationProvider>
        <b-overlay id="overlay-background" class="mx-auto d-block passrec-overlay-width my-5" :show="show" rounded="sm">
          <b-button type="submit" class="btn-form mx-auto" variant="primary">Cambiar Password</b-button>
        </b-overlay>
        </b-form>
      </ValidationObserver>
      <b-alert variant="danger" class="alert" :show="dismissCountDown"  @dismissed="dismissCountDown = 0">{{errors}}</b-alert>
    </b-card>
  </b-container>
</template>
<script>

import { encryptString, decryptString, getRandomString } from '../../encryption/encryption'

export default {
  name: 'PasswordRecoveryForm',

  data(){
    return{
      repeatPass:'',
      password:'',
      show:false,
      errors:'',
      dismissSecs: 150,
      dismissCountDown: 0,
    }
  },
  created(){
    this.axios.get('checkRecoveryPasswordForm/' + this.$route.params.token).catch(error=>{
      if(error.response.status){
        this.$router.push('/error')
      }
      this.show = false
    })
    document.title = 'Cambiar contraseña en Tony Bambino'
  },
  methods:{
    async changePassword() {
      const success = await this.$refs.passwordRecovery.validate()
      if(!success){
        return false
      }
      this.show = true
      let password = encryptString(this.password)
      let email = decryptString(this.$route.params.encrypt,50)
      let token = this.$route.params.token
      let repeatPass =  encryptString(this.repeatPass)

      this.axios.post('reset-password',{ email, password, token, repeatPass }).then(response => {
        this.$store.dispatch('setToken', response.data.token)
        this.$store.dispatch('setUsername', response.data.username)
        this.$store.dispatch('checkUserAuth', response.data.username)
        this.$store.dispatch('fetchCartItems', response.data.username)
        this.$root.$emit('isLoggedIn',response.data.username)
        let successToken = getRandomString(500)
        sessionStorage.setItem('scss_t', successToken)
        this.$router.push({name: 'PasswordRecoverySuccess', params:{ token:successToken }})
        this.show = false

      }).catch(error=>{
        if(error.response.status){
          this.errors = 'Ha habido un error de conexión. Por favor recarge la página, si el error persiste contacte con nosotros'
          this.dismissCountDown = this.dismissSecs
        }
      })
    }
  },
}
</script>


