<template>
    <b-container>
		<h3 class="notAllowed text-center">Su contraseña ha sido cambiada</h3>  
    </b-container>
</template>
<script>
import { getCookie } from '../../cookies'
export default {
    name: 'PasswordChanged',
    methods:{
        checkPage(){
            if(getCookie('s_nrxzs') === null){
                this.$router.push({ name:'Error404' })
            }   
        }
    },
    created(){
        this.checkPage()
        document.title = 'Contraseña Cambiada'
    }
}
</script>