/*-----Imports-------*/

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vueRouter from 'vue-router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'materialize-css/dist/css/materialize.css'
import Materialize from 'materialize-css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import VueAgile from 'vue-agile'
import ProductZoomer from 'vue-product-zoomer'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import Vuex from 'vuex'
import VueMoment from 'vue-moment'
import App from './App.vue'
import Routes from './routes'
import store from './store'
import VueCarousel from 'vue-carousel'
/*-----Require-------*/

require('./bootstrap')
require('./formValidation')

/*-----Libraries-------*/

library.add(fab)


Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

/*-----Vue use-------*/

Vue.use(VueAxios, axios)
Vue.use(vueRouter)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Materialize)
Vue.use(VueAgile)
Vue.use(ProductZoomer)
Vue.use(Vuetify)
Vue.use(Vuex)
Vue.use(VueMoment)
Vue.use(VueCarousel)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)


/*-----Axios Headers Common-------*/
require('./headers/headers')

/*--route auth login--*/

const routes = new vueRouter({
  mode: 'history',
  routes: Routes
})

routes.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = store.state.auth

  if (requiresAuth && !isAuthenticated) {
    next({ name: "NotAllowed" })
  } else if(isAuthenticated && to.name === 'Login' || isAuthenticated &&  to.name === 'ForgotPassword' ||
    isAuthenticated &&  to.name === 'Registered' || isAuthenticated && to.name === 'Register'){
    next({ name: "Welcome" })
  }else{
    next() 
  }
})

/*--route year and season check--*/
routes.beforeEach((to, from, next) => {
  if (to.matched.some(record => to.params.year >= record.meta.year && to.params.season === record.meta.season || to.params.year > record.meta.year)) {
    next({ name: "WrongCollection" })
  } else {
    next()
  }
})


Vue.config.productionTip = false

/*---Vue creation---*/

new Vue({
  el: '#app',
  vuetify: new Vuetify(),
  store,
  router: routes,
  render: h => h(App),
})


