<template>
    <b-container>
        <b-row class="my-5">
            <h3 class="mx-auto formTitle text-center s-title-width">Resultados de la búsqueda: "{{ this.$route.params.search }}"</h3>
        </b-row>
        <b-row v-if="search.length === 0">
            <h4 class="notAllowed text-center mx-auto">{{  checkSearch }}</h4>
        </b-row>
        <b-row v-else>
            <b-col class="col-12 col-sm-6 col-md-4 mx-auto my-5" center v-for="(product, index) in search" :key="index">
                <router-link class="imgTitle" :to="{name:'Product', params:{ season: product.temporada, year: product.año, family:product.familia, product: product.modelo }}">
                    <b-img center thumbnail fluid :src="'https://ctworker.com/images/gallery_size/' + product.modelo + '.jpg'"  class="imgBorder hoverHand" width="300" height="auto" :alt="'ver ' + product.familia"></b-img>
                    <h5 class="text-center mx-auto mt-2 w-50 hoverHand mb-5">{{ product.modelo }}</h5>
                </router-link>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
    name: 'Searcher',
    data() {
        return {
            search: [],
        }
    },
    created(){
        this.$store.dispatch('makeSearch', this.$route.params.search) 
    },
    computed:{
        searchResult(){  
            document.title = 'Búsqueda - ' + this.$route.params.search
            return this.$store.getters.search
        },
        checkSearch(){
            var message = 'Cargando...' 
            if(this.$store.getters.search.length === 0){
                message = 'No hay resultados en su búsqueda'
            }
            return message
        }
    },
    watch:{
        searchResult:{
            handler:function(search){
                this.$root.$emit('loader', false)
                this.search = search
            }
        },
    },
};
</script>
