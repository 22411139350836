<template>
    <b-container>
		<h3 class="mx-auto formTitle mb-5 c-title-width">Contacto</h3>
        <p class="text-center formText mx-auto my-3 c-form-width ">
            Si tienes alguna duda o quieres que te proporcionemos más información acerca de nosotros. Rellena el siguiente formulario para que podamos contactar contigo lo antes posible. ¡Muchas Gracias!
        </p>
        <b-card class="c-form-width mx-auto">
            <ValidationObserver ref="observer">
                <b-form @submit.prevent="sendContact" class="mx-3" refs="contactForm">
                    <ValidationProvider rules="required">
                        <div class="input-field mt-4" slot-scope="{ valid, errors }">
                            <b-input id="nombre" v-model="form.name" :state="errors[0] ? false : (valid ? true : null)"></b-input>
                            <label for="nombre">Nombre y Apellidos*</label>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                    <b-row>
                        <ValidationProvider rules="required|numeric" class="col-12 col-md-6">
                            <div class="input-field" slot-scope="{ valid, errors }">
                                <b-input id="phone" v-model="form.phone" :state="errors[0] ? false : (valid ? true : null)"></b-input>
                                <label for="phone">Teléfono*</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required|email" class="col-12 col-md-6">
                            <div class="input-field" slot-scope="{ valid, errors }">
                                <b-input id="email" v-model="form.email" :state="errors[0] ? false : (valid ? true : null)"></b-input>
                                <label for="email">Email*</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </b-row>
                    <b-row class="mt-0">
                        <ValidationProvider rules="required" class="col-12 col-md-3">
                            <div class="input-field" slot-scope="{ valid, errors }">
                                <b-input id="provincia" v-model="form.region" :state="errors[0] ? false : (valid ? true : null)"></b-input>
                                <label for="provincia">Provincia*</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required" class="col-12 col-md-3">
                            <div class="input-field" slot-scope="{ valid, errors }">
                                <b-input id="city" v-model="form.city" :state="errors[0] ? false : (valid ? true : null)"></b-input>
                                <label for="city">Localidad*</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required" class="col-12 col-md-3">
                            <div class="input-field"  slot-scope="{ valid, errors }">
                                <b-form-input id="cp" v-model="form.country" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="cp">País*</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required|numeric" class="col-12 col-md-3">
                            <div class="input-field"  slot-scope="{ valid, errors }">
                                <b-form-input id="cp" v-model="form.cp" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="cp">Código Postal*</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </b-row>
                    <b-row>
                    <ValidationProvider rules="required">
                        <div class="input-field mb-5" slot-scope="{ valid, errors }">
                            <label for="Mensaje">Mensaje*</label>
                            <b-form-input id="Mensaje" v-model="form.message" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                    </b-row>
                    <b-overlay id="overlay-background" class="w-25 mx-auto sendButton" :show="show" :variant="variant" rounded="sm">
                        <b-button type="submit" block class="mb-5 mx-auto d-block btn-form w-100" variant="primary">Enviar</b-button>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
            <p class="font text-center">
                Al enviar el formulario de contacto aceptas nuestras <router-link :to="{ name:'Condiciones' }"> Condiciones generales</router-link> de uso y venta. Consulta nuestra<router-link :to="{ name:'ProteccionDatos' }">  Política de Privacidad</router-link> y  nuestro<router-link :to="{ name:'Cookies' }"> Política de Cookies</router-link>.
            </p>
        </b-card>
            <transition name="fade">
                <b-alert variant="success" class="mb-4 text-center mx-auto" :show="dismissCountDown" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">El mensaje ha sido enviado correctamente! Le contestaremos en menos de 48 horas</b-alert>
            </transition>
            <b-alert variant="danger" class="mb-4 text-center" :show="dismissCountDown2"  @dismissed="dismissCountDown2=0" @dismiss-count-down2="countDownChanged2">Ha habido un error! Por favor recargue la página</b-alert>
    </b-container>
</template>
<script>
import { extend } from 'vee-validate'
export default {
    name: 'Contact',
    data() {
        return {
            variant: 'light',
            dismissSecs: 12,
            dismissCountDown: 0,
            dismissCountDown2: 0,
            show: false,
            counter: 0,
            form: {
                name:"",
                phone:"",
                email:"",
                region:"",
                city:"",
                cp:"",
                message:"",
                country:""
        },
      }
    },
    created(){
        document.title = 'Contacta con Tony Bambino'
    },
    methods : {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        countDownChanged2(dismissCountDown2) {
            this.dismissCountDown2 = dismissCountDown2
        },
        sendContact() {
            if(this.counter === 1){
                return alert('Ya hemos enviado su mensaje de contacto y recibirá contestación dentro de 24/48 horas')
            }
            this.show = true
            let name = this.form.name
            let phone = this.form.phone
            let email = this.form.email
            let region = this.form.region
            let city = this.form.city
            let cp = this.form.cp
            let message = this.form.message
            let country = this.form.country
            this.axios.post('sendEmailFromContactForm', {name, phone, email, region, city, cp, message, country}).then(response => {
                let data = response.status;
                if(data === 200){
                    extend('required', {
                        validate() {
                        return '';
                        },
                    });
                    this.show = false
                    this.dismissCountDown = this.dismissSecs
                    this.form.name = ""
                    this.form.phone = ""
                    this.form.email = ""
                    this.form.region = ""
                    this.form.city = ""
                    this.form.cp = ""
                    this.form.message = ""
                    this.form.country = ""

                    requestAnimationFrame(() => {
                        this.$refs.observer.reset();
                    });
                }
                return this.counter ++
            }).catch((error) => {
                if(error.response.status){
                    this.dismissCountDown2 = this.dismissSecs
                }
            })
        }
    },
}
</script>
