
export function getRandomString(length) {
  var randomChars = '-.;0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz'
  var result = ''
  for ( var i = 0; i < length; i++ ) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
  }
  return result
}

export function  encryptString(string){
    var pass = string.split('')
    var arr= []
    var token = getRandomString(1077)
    for(var i = 0; i < pass.length; i ++){
      var md5 = require("md5")
      var t = md5(token[i])
      arr.push(t + pass[i])
    }
    var x = arr.toString()
    var finalString = x.replaceAll(',', '')
    return finalString
}

export function decryptString(string, number){
  var arr = []
  
  for(var i = 0; i < string.length; i ++){
    i = i + number
    arr.push(string[i]) 
  }
  return arr.toString().replaceAll(',', '')  
}
