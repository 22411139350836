<template>
    <b-container>
        <b-card class="da-card-width mx-auto">
            <ValidationObserver ref="observer">
                <b-form refs="form" @submit.prevent="setShippingAdress(info.nombre, info.apellidos, info.cif, info.empresa, info.direccion, info.codigo_postal, info.ciudad, info.provincia, info.pais,
                info.email, info.telefono)" v-for="(info, Index) in shippingAdress" :key="Index">
                    <h4 class="text-center session-title">Dirección de Envío</h4>
                    <b-row>
                        <ValidationProvider rules="required" class="col-12 col-md-6">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="nombre" v-model="info.nombre" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="nombre" class="active">Nombre</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required" class="col-12 col-md-6">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="apellidos" v-model="info.apellidos" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="apellidos" class="active">Apellidos</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </b-row>
                    <b-row class="mb-0">
                        <ValidationProvider  rules="required|vat" class="col-12 col-md-3">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="cif" v-model="info.cif" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="cif" class="active">DNI/CIF</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required" class="col-12 col-md-9">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="empresa" v-model="info.empresa" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="empresa" class="active">Empresa</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </b-row>
                    <ValidationProvider rules="required" class="col-12 col-md-3">
                        <div class="input-field mt-0" slot-scope="{ valid, errors }">
                            <b-form-input id="direccion" v-model="info.direccion" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                            <label for="direccion" class="active">Dirección</label>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                    <b-row class="mb-0">
                        <ValidationProvider rules="required" class="col-12 col-md-3">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="cp" v-model="info.codigo_postal" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="cp" class="active">Código Postal</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required" class="col-12 col-md-9">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="poblacion" v-model="info.ciudad" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="poblacion" class="active">Población</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </b-row>
                    <b-row class="mb-0">
                        <ValidationProvider rules="required" class="col-12 col-md-6">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="provincia" v-model="info.provincia" :state="errors[0] ? false : (valid ? true : null)">
                                </b-form-input>
                                <label for="provincia" class="active">Provincia</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider class="col-12 col-md-6 pb-0">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <label for="country" class="active">País</label>
                                <select name="country" id="country" class="form-control" v-model="selectedCountry" :state="errors[0] ? false : (valid ? true : null)">
                                    <b-form-select-option value="">{{ info.pais }}</b-form-select-option>
                                    <b-form-select-option :value="country.name_es" v-for="(country, Index) in countriesList" :key="Index">
                                        {{ country.name_es }}
                                    </b-form-select-option>
                                </select>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </b-row>
                    <b-row class="mb-0">
                        <ValidationProvider rules="required|email" class="col-12 col-md-9">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="email" v-model="info.email" :state="errors[0] ? false : (valid ? true : null)">
                                </b-form-input>
                                <label for="email" class="active">email</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required|numeric" class="col-12 col-md-3">
                            <div class="input-field mt-0" slot-scope="{ valid, errors }">
                                <b-form-input id="telefono" v-model="info.telefono" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <label for="telefono" class="active">Teléfono</label>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </b-row>
                    <b-overlay id="overlay-background" class="mx-auto d-block adr-overlay-width" :show="show" rounded="sm">
                        <b-button type="submit" class="mx-auto d-block btn-form my-4" variant="primary">Editar</b-button>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
            <transition name="fade">
                <b-alert variant="success" class="mb-4" :show="dismissCountDown" @dismissed="dismissCountDown = 0">Datos cambiados</b-alert>
            </transition>
            <b-alert variant="danger" class="mb-4" :show="dismissCountDown2"  @dismissed="dismissCountDown2 = 0">{{ errors }}</b-alert>
        </b-card>
        <UserParamLink :message="'Volver a Mis Direcciones'" class="my-5" :url="'Adresses'"></UserParamLink>
    </b-container>
</template>
<script>
import UserParamLink from "../buttons/UserParamLink"
export default {
    name: 'ShippingAdressFormFilled',
    components: {
        UserParamLink
    },
    data() {

        return {
            user:this.$store.getters.username,
            shippingAdress:[],
            dismissSecs: 8,
            dismissCountDown: 0,
            dismissCountDown2: 0,
            errors: '',
            countriesList: [],
            selectedCountry: '',
            show: false
        }
    },
    created() {
        this.axios.get('getShippingAdressInfoById/' + this.$route.params.id + '/' + this.$store.getters.username)
            .then((response) => { this.shippingAdress = response.data })
        this.axios.get('getAllCountries')
            .then((response) => { this.countriesList = response.data })
    },

    created() {
        this.axios.get('getShippingAdressInfoById/' + this.$route.params.id + '/' + this.$store.getters.username)
            .then((response) => { this.shippingAdress = response.data })
        this.axios.get('getAllCountries')
            .then((response) => { this.countriesList = response.data })
    },

    methods: {
        async setShippingAdress(name, surname, cif, business, adress, postalCode, city, region, country, email, telephone) {
            this.show = true
            let selectedCountry = this.selectedCountry
            const success = await this.$refs.observer.validate()

            if (!success) {
                this.show = false
                this.errors = '¡Su formulario tiene errores!'
                this.dismissCountDown2 = this.dismissSecs
                return false
            }
            if (selectedCountry === '') {
                selectedCountry = country
            }

            const formInfo = {
                nombre: name,
                apellidos: surname,
                empresa: business,
                direccion: adress,
                cp: postalCode,
                provincia: region,
                ciudad: city,
                email: email,
                telefono: telephone,
                cif: cif,
                id: this.$route.params.id,
                pais: selectedCountry
            }

            this.axios.post('setShippingAdressInfoById', { formInfo })
                .then(() => {
                    this.dismissCountDown = this.dismissSecs
                    this.show = false
                })
                .catch(error => {
                    if (error.response.status) {
                        this.errors = 'Ha habido un error! Por favor recargue la página'
                        this.dismissCountDown2 = this.dismissSecs
                    }
                    this.show = false
                })
        },
    },
}
</script>

