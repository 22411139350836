<template>
    <b-container v-if="inCartItems.length === 0" class="mb-4">
        <h4 class="empty-basket text-center">{{checkEmptyBasket}}</h4>
    </b-container>
    <b-container v-else>
        <div class="d-none d-sm-block basket-desk-width mx-auto">
            <table class="table ml-0 mt-5">
                <thead>
                    <tr class="abril-fatface">
                        <th scope="col"></th>
                        <th scope="col">MODELO</th>
                        <th scope="col">COLOR</th>
                        <th scope="col">PRECIO</th>
                        <th scope="col" class="text-center w-25">CANTIDAD</th>
                        <th scope="col" class="text-center">SUBTOTAL</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, Index) in inCartItems" :key="Index" scope="row">
                        <td>
                            <router-link :to="{ name:'Product', params:{season: item.season.toLowerCase(), year: item.year, family:item.family, product: item.modelo} }">
                                <b-img thumbnail fluid width="200" height="auto" :src="'https://ctworker.com/images/basket_size/' + item.modelo + '.jpg' " alt="Image 1"></b-img>
                            </router-link>
                        </td>
                        <td class="basketItem">
                            <router-link class="basketItem" :to="{ name:'Product', params:{season: item.season.toLowerCase(), year: item.year, family:item.family, product: item.modelo} }">
                                <p class="mb-0" v-if="item.talla != 'unica'">
                                    {{ item.modelo }}-{{ item.talla }}
                                    <span v-if="item.sizes === 1 && item.talla === 1 ">año</span>
                                    <span v-else-if="item.sizes === 0 && item.talla === 1">mes</span>
                                    <span v-else-if="item.sizes === 1">años</span>
                                    <span v-else-if="item.sizes === 0">meses</span>
                                </p>
                                <p class="mb-0" v-else>{{ item.modelo }}-Única</p>
                            </router-link>
                        </td>
                        <td class="basketItem">{{ item.color }}</td>
                        <td class="basketItem">{{ fixNumber(item.precio) }} €</td>
                        <td class="basketItem text-center">
                            <div class="basket-overlay mx-auto">
                            <b-overlay id="overlay-background" :show="show2" :variant="variant" rounded="xs">
                                <button @click="decreaseInBasket(item.id)" class="mt-2">
                                    <i class="material-icons">remove_circle</i>
                                </button>
                                    <span :id="'popover'+ item.id" class="mx-3">{{ item.cantidad }}</span>
                                <button @click="increaseInBasket(item.id)">
                                    <i class="material-icons pl-3">add_circle</i>
                                </button>
                            </b-overlay>
                            </div>
                        </td>
                        <td class="basketItem text-center">{{ fixNumber(item.cantidad * item.precio) }} €</td>
                        <td class="basketItem btn-light">
                            <button @click="deleteInBasket(item.id)">
                                <i class="material-icons">delete</i>
                            </button>
                        </td>
                    <b-popover :target="'popover' + item.id" variant="danger" ref="popover" placement="top"> No hay más unidades</b-popover>
                    </tr>
                </tbody>
            </table>
            <div class="payment-info">
                <h5 class="abril-fatface mt-5 mb-2">
                    (Total {{ iva + re }}): {{ fixNumber(total()) }} €
                </h5>
                <h5 class="abril-fatface mt-3 mb-2">Envío:
                    <span v-if="mailCost() === 1.11">Por determinar</span>
                    <span v-else>{{ mailCost() + ' €'}}</span>
                </h5>
                <h5 class="abril-fatface mt-3 mb-2">(Total + Envío):
                    <span class="text-dark mt-2" v-if="mailCost() === 1.11">{{ fixNumber(total()) }}€</span>
                    <span class="text-dark mt-2" v-else>{{ fixNumber(total() + mailCost()) }}€</span>
                </h5>
                <h6 class="mt-5 text-danger" v-if="shippingAdress.length === 0">¡Faltan datos en tu información de envío!</h6>
                <h6 class="mt-3 text-danger" v-if="billingAdress.length === 0 ">¡Faltan datos en tu información de Facturación!</h6>
                <a id="shippingAdress" class="text-right abril-fatface mt-3 d-block" v-b-modal.modal-1 v-if="shippingAdress.length > 0">Enviar a {{ shippingAdress.find(x=> x.selected === 1)['ciudad'] + '(' + shippingAdress.find(x=> x.selected === 1)['provincia'] + ')' }}</a>
                <a class="abril-fatface mt-3 d-block" v-b-modal.modal-1 v-else>(Cambiar datos de envío)</a>
                <a class="abril-fatface mt-3 d-block" v-b-modal.modal-2>(Cambiar datos de facturación)</a>
                <b-alert variant="info" ref="popover" show v-if="surCharge === null" class="surcharge-alert d-block">Debe indicar si tiene recargo de equivalencia <router-link :to="{ name:'MyAccountDetails',params:{ user:user } }">Aquí</router-link></b-alert>
                <b-overlay id="overlay-background" class="my-4 b-overlay-cart order-button" :show="show" :variant="variant" rounded="sm">
                    <b-button @click="submit()" class="d-block btn-form" variant="primary" :disabled="isDisabled">Tramitar Pedido</b-button>
                </b-overlay>
            </div>
        </div>
        <div class="d-block d-sm-none mobile-cart-view font mx-auto">
            <p class="my-3 text-center" style="font-size: 16px">Total {{ iva + re }} + (Envío:
                <span v-if="this.mailCost() === 1.11">Por determinar</span>
                <span v-else>{{ this.mailCost() + ' €'}}</span>):
                <span class="text-dark mt-2" style="font-size: 20px" v-if="this.mailCost() === 1.11">{{ fixNumber(total()) }} €</span>
                <span class="text-dark mt-2" style="font-size: 20px" v-else>{{ fixNumber(total() + this.mailCost()) }} €</span>
            </p>
            <b-alert  variant="info" ref="popover" show v-if="surCharge === null">Indique si tiene recargo de equivalencia <router-link :to="{ name:'MyAccountDetails',params:{ user:user } }">Aquí</router-link></b-alert>
            <div :class="sticky">
                <b-overlay id="overlay-background" :show="show" class="overlay" rounded="sm">
                    <b-button @click="submit" :class="'mb-2 btn-form w-100 ' + stickyButton" block variant="primary" :disabled="isDisabled">Tramitar Pedido (total:
                        <span v-if="this.mailCost() === 1.11">{{ fixNumber(total()) }}</span>
                        <span v-else>{{ fixNumber(total() + this.mailCost()) }}</span>)
                    </b-button>
                </b-overlay>
            </div>
            <a id="shippingAdress" class="abril-fatface mt-4 d-block" v-b-modal.modal-1 v-if="shippingAdress.length > 0">Enviar a {{ shippingAdress.find(x=> x.selected === 1)['ciudad'] + '(' + shippingAdress.find(x=> x.selected === 1)['provincia'] + ')' }}</a>
            <a id="shippingAdress" class="abril-fatface d-block" v-b-modal.modal-1  v-else>(Cambiar datos de envío)</a>
            <a id="billingAdress" class="abril-fatface d-block" v-b-modal.modal-2>(Cambiar datos de facturación)</a>
            <p class="mt-2 text-danger" v-if="shippingAdress.length === 0">¡Faltan datos en tu información de envío!</p>
            <p class="mt-1 text-danger" v-if="billingAdress.length === 0 ">¡Faltan datos en tu información de Facturación!</p>
            <b-media class="border-top border-bottom basketItem my-2 pt-5" v-for="(item, Index) in inCartItems" :key="Index">
                <b-overlay id="overlay-background" :show="show2" :variant="variant" rounded="sm">
                    <b-row class="mx-auto">
                        <b-col class="ml-3 mb-3">
                            <router-link :to="{ name:'Product', params:{season: item.season.toLowerCase(), year: item.year, family:item.family, product: item.modelo} }">
                                <b-img fluid width="120" height="auto" :src="'https://ctworker.com/images/basket_size/' + item.modelo + '.jpg'" alt="Image 1"></b-img>
                            </router-link>
                        </b-col>
                        <b-col class="mt-2">
                            <router-link class="basket-item-xs" :to="{ name:'Product', params:{season: item.season, year: item.year, family:item.family, product: item.modelo} }">Mod. {{ item.modelo }}</router-link>
                            <p v-if="item.talla != 'Única'" class="m-0 font">{{ item.talla }}
                                <span v-if="item.sizes === 1 && item.talla === 1 ">año</span>
                                <span v-else-if="item.sizes === 0 && item.talla === 1">mes</span>
                                <span v-else-if="item.sizes === 1">años</span>
                                <span v-else-if="item.sizes === 0">meses</span>
                            </p>
                            <p class="m-0 font" v-else>Talla Única</p>
                            <p class="font m-0">{{ item.color }}</p>
                            <p class="text-dark mb-0"><span style="font-size: 20px">{{ item.precio }}€ </span></p>
                            <p v-if="item.cantidad > 1" class="font m-0">Subtotal:
                                <span class="text-dark"></span><span class="text-dark" style="font-size: 20px"> {{ (item.cantidad * item.precio).toFixed(2) }}€</span>
                            </p>
                        </b-col>
                    </b-row>
                    <div class="my-3">
                        <b-button @click="decreaseInBasket(item.id)" variant="primary" class="btn-form mt-2">
                            <i class="material-icons">remove</i>
                        </b-button>
                        <span :id="'popover2' + item.id" class="px-3">{{ item.cantidad }}</span>
                        <b-button class="btn-form mt-2" @click="increaseInBasket(item.id)" variant="primary">
                            <i class="material-icons">add</i>
                        </b-button>
                        <b-button @click="deleteInBasket(item.id)" class="btn-form delete mt-2" variant="primary">Eliminar</b-button>
                    </div>
                    <b-popover :target="'popover2' + item.id" variant="danger" ref="popover" placement="top">No hay más unidades</b-popover>
                </b-overlay>
            </b-media>
        </div>
        <b-modal id="modal-1" size="lg"  hide-footer no-stacking :title="'Dirección de Envío'">
            <ShippingAdressSelector></ShippingAdressSelector>
        </b-modal>
        <b-modal  id="modal-2" size="lg"  hide-footer no-stacking :title="'Dirección de Facturación'">
            <BillingAdressSelector></BillingAdressSelector>
        </b-modal>
        <b-alert variant="danger" class="mb-4" :show="dismissCountDown"  @dismissed="dismissCountDown = 0">Ha habido un error! Por favor recargue la página. Si el error persiste, por favor contacte al teléfono 953741829</b-alert>
    </b-container>
</template>
<script>
import ShippingAdressSelector from "./adressComponents/ShippingAdressSelector"
import BillingAdressSelector from "./adressComponents/BillingAdressSelector"
import { getRandomString } from '../encryption/encryption'
import { setCookie } from '../cookies'

export default {
    name: 'Basket',
    components: {
        ShippingAdressSelector,
        BillingAdressSelector
    },
    props:{
        fixNumber: Function
    },
    data(){
        return{
            user: this.$store.getters.username,
            tallas:[],
            loader: false,
            dismissSecs: 10,
            dismissCountDown: 0,
            userInfo:[],
            shippingAdress:[],
            billingAdress:[],
            show: false,
            variant: 'light',
            sticky: '',
            newSeason: false,
            re: '',
            iva: '',
            disabled:false,
            surCharge: 0,
            show2: false,
            stickyButton: ''
        }
    },

    created() {
        this.axios.get('getInfoByUser/' + this.user ).then(response=> (this.userInfo = response.data))
        this.axios.get('getShippingAdressInfo/' + this.user).then(response=> { this.shippingAdress = response.data})
        this.axios.get('getBillingAdressInfo/' + this.user).then(response=> { this.billingAdress = response.data})
        this.$root.$on('adressInfo', adressInfo =>{
            adressInfo.then(response =>{ this.shippingAdress = response.data})
        })
        this.$root.$on('billingInfo', billingInfo =>{
            billingInfo.then(response =>{ this.billingAdress = response.data})
        })
        this.$store.dispatch('fetchCartItems', this.user)
        document.title = 'Cesta de la compra'
    },

    mounted() {
        window.addEventListener("scroll", this.handleScroll)
    },

    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    computed:{

        isDisabled() {
            var disabled
            if(this.userInfo.find(x=> x.recargo === null) || this.shippingAdress.length === 0 || this.billingAdress.length === 0 ) {
                disabled = true
            }
            if(this.billingAdress.find(x=> x.country != 'España')){
                disabled = false
            }
            return disabled
        },

        isSurCharge() {
            var charge
            if(this.billingAdress.find(i=> i.country === 'España' && i.selected === 1) && this.userInfo.find(x=> x.recargo === null)){
                charge = null
            }
            if(this.billingAdress.find(i=> i.country === 'España' && i.selected === 1) && this.userInfo.find(x=> x.recargo === 1)){
                charge = 1
            }
            if(this.billingAdress.find(i=> i.country !== 'España')){
                charge = 0
            }
            return charge
        },

        checkEmptyBasket() {
            var message = 'Cargando...'
            if(this.$store.getters.inCart === 0){
                message = 'No tiene ningún producto en su cesta'
            }
            return message
        },

        inCartItems() {
            if(this.$store.getters.inCartItems){
                this.$root.$emit('loader', false)
            }
            return this.$store.getters.inCartItems
        },
    },

    watch:{
        isSurCharge:{
            handler: function(charge) {
                this.surCharge = charge
            }
        },
    },

    methods: {
        handleScroll() {
            if (window.scrollY > 200) {
                this.sticky = 'sticky sticky'
                this.stickyButton = 'sticky-button'
            } else {
                this.sticky = ''
                this.stickyButton = ''
            }
        },

        close() {
            this.billingAdress.length = 1
            this.$root.$emit('bv::hide::popover', 'billingAdress')
        },

        match(id) {
            var match = this.inCartItems.find(compra => compra.id === id)
            return match
        },

        createItemForCart(match) {
            var itemForCart =
            {
                modelo: match['modelo'],
                id_color: match['id_color'].substr(0,2),
                talla: match['talla'],
                color: match['color'],
                new: match['new'],
                index: this.inCartItems.indexOf(match),
                cantidad: match['cantidad'],
                precio: match['precio'],
                family: match['family'],
                season: match['season'],
                year:match['year'],
                description:match['description'],
                sizes:match['sizes']
            }
            return itemForCart
        },

        storeOrUpdateItemInCart(user, item) {
            this.axios.post('storeOrUpdateItemInCart', { user, item }).catch((error) => {
                if(error.response.status === 500){
                    console.log('no se puede actualizar')
                }
            });
        },

        increaseInBasket(id) {
            var match = this.match(id)
            var item = this.createItemForCart(match)
            var user = this.user
            this.show2 = true
            this.axios.get('getProductSizesByColor/' + match['modelo'] + '/' + match['id_color']).then(response => {
                this.tallas = response.data
                if (match['cantidad'] < this.tallas[0]['talla_' + match['talla']] && match['new'] === 0 || match['new'] === 1) {
                    match['cantidad']++
                    item['cantidad'] = 1
                    this.storeOrUpdateItemInCart(user, item)
                    this.$store.dispatch('addToCart', 1)
                } else {
                    this.$root.$emit('bv::show::popover', 'popover' + id)
                    this.$root.$emit('bv::show::popover', 'popover2' + id)
                }
                this.show2 = false
            })
        },

        decreaseInBasket(id) {
            this.show2 = true
            var match = this.match(id)
            var item = this.createItemForCart(match)
            var user = this.user
            this.$store.dispatch('removeFromCart', 1)

            this.axios.get('getProductSizesByColor/' + match['modelo'] + '/' + match['id_color']).then(response => {
                this.tallas = response.data
                match['cantidad']--

                if (match['talla'] === 'Única') {
                    match['talla'] = 'unica'
                }

                item['cantidad'] = -1
                this.storeOrUpdateItemInCart(user, item)

                if (match['cantidad'] === 0) {
                    var index = this.inCartItems.indexOf(match)
                    this.inCartItems.splice(index, 1)
                }

                if (match['cantidad'] < this.tallas[0]['talla_' + match['talla']]) {
                    this.$root.$emit('bv::hide::popover', 'popover'+id)
                    this.$root.$emit('bv::hide::popover', 'popover2'+id)
                }
                this.show2 = false
            })
        },

        deleteInBasket(id) {
            var match = this.match(id)
            var index = this.inCartItems.indexOf(match)
            var item = this.createItemForCart(match)
            var user = this.user
            this.$store.dispatch('emptyItemInCart', match['cantidad'])
            this.inCartItems.splice(index, 1)

            this.axios.post('setSizeToZero', { user, item })
                .catch((error) => {

                    if (error.response) {
                        return this.dismissCountDown2 = this.dismissSecs
                    }
                })
        },

        submit() {
            this.show = true
            this.$root.$emit('loader', this.loader)
            let orderInfo = this.userInfo
            let orderPrice = this.total().toFixed(2)
            let email = this.userInfo[0]['email']
            let shippingCost = this.mailCost()
            let newSeason = this.newSeason
            let brand = 'tony'

            this.axios.post('setOrderInfo', { orderInfo }).then(response => {

                let host = process.env.APP_ENV === 'localhost' ? process.env.VUE_APP_URL_LOCALHOST : process.env.VUE_APP_URL
                let orderNumber = response.data
                localStorage.setItem('order_number', orderNumber)

                if (shippingCost === 1.11 || this.newSeason === true || this.newSeason === false) {
                    return this.axios.post('setOrderSuccessPrice',{ orderPrice, shippingCost, orderNumber, newSeason }).then(() => {
                        var token = getRandomString(1088)
                        setCookie('ord_scss', token, 0.02)
                        return this.$router.push({ name: 'SuccessOrder', params: { session_id:token } })
                    })
                }

                this.axios.post('checkOutPayment' , { orderPrice, orderNumber, email, shippingCost, host, brand })
                    .then((response) => {
                        var url = response.data
                        this.$root.$emit('loader', !this.loader)

                        return window.location.href = url
                    }).catch(error => {

                        if (error.response) {
                            return this.dismissCountDown2 = this.dismissSecs
                        }
                    })
                }).catch(error => {

                    if (error.response) {
                        return this.dismissCountDown2 = this.dismissSecs
                    }
                })
        },

        total() {
            var total = 0
            var iva = 0.21

            for (var i = 0; i < this.inCartItems.length; i++) {
                var cantidad = JSON.stringify(this.inCartItems[i]['cantidad'])
                var precio = JSON.stringify(this.inCartItems[i]['precio'])
                total += parseFloat(cantidad) * parseFloat(precio)
            }

            if (this.surCharge === 1) {
                this.re = ' + R.E: 5,2%'
                iva = 0.21 + 0.052
            }

            if (this.billingCountry() ==='España') {
                this.iva = ' + IVA: 21%'
                total = total + (total * iva)
            }
            return total
        },

        billingCountry() {
            let country

            if (this.billingAdress.length > 0) {
                country = this.billingAdress.find(x=> x.selected === 1)['country']
            }
            return country
        },

        shippingCountry() {
            let country

            if (this.shippingAdress.length > 0) {
                country = this.shippingAdress.find(x => x.selected === 1)['pais']
            }

            return country
        },

        mailCost() {
            var cost = 1.11

            if (this.shippingCountry() === 'España' && this.total() < 300) {
                cost = 9.00
            }

            if (this.shippingCountry() === 'Gran Bretaña' && this.total < 600) {
                cost =  47.00
            }

            if (this.shippingCountry() === 'Gran Bretaña' && this.total > 600 || this.shippingCountry() === 'España' && this.total > 300) {
                cost =  0.00
            }

            return cost
        },
    },
}
</script>
