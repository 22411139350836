<template>
    <div>
    <b-card class="mx-3 session-form register-form-heigth mb-5">
        <ValidationObserver ref="register">
            <b-form  @submit.prevent="onSubmit">
                <h3 class="text-center session-title">Regístrate</h3>
                <div class="my-auto d-block mx-3">
                    <ValidationProvider name="customer" rules="required|customer|espacios">
                        <div class="input-field mt-5" slot-scope="{ valid, errors }">
                            <label for="customer">Número de Cliente</label>
                            <b-form-input id="customer" :state="errors[0] ? false : (valid ? true : null)" v-model="customer" type="number"></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider rules="required|minmax:4,nombre de usuario,caracteres,El|espacios" name="user">
                        <div class="input-field mt-4" slot-scope="{ valid, errors }">
                            <label for="user">Nombre de Usuario</label>
                            <b-form-input id="user" :state="errors[0] ? false : (valid ? true : null)" v-model="user"></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider rules="required|email|espacios" name="email">
                        <div class="input-field mt-4" slot-scope="{ valid, errors }">
                            <label for="email">email</label>
                            <b-form-input type="email" id="email" :state="errors[0] ? false : (valid ? true : null)" v-model="email" ></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider rules="required|minmax:8,contraseña,caracteres, La|espacios" name="pass">
                        <div class="input-field mt-4" slot-scope="{ valid, errors }">
                            <label for="pass">Contraseña</label>
                            <b-form-input id="pass" ref="pass" aria-describedby="password-help-block" :state="errors[0] ? false : (valid ? true : null)" v-model="pass" type="password"></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0]}}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider rules="required|minmax:8,contraseña,caracteres, La|espacios" name="repeatpass">
                        <div class="input-field mt-4" slot-scope="{ valid, errors }">
                            <label for="repeatpass">Repetir Contraseña</label>
                            <b-form-input id="repeatpass" aria-describedby="password-help-block" :state="errors[0] ? false : (valid ? true : null)" v-model="repeatpass" type="password"></b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>
                </div>
            <b-button type="submit" class="my-5 mx-auto btn-form d-block" variant="primary">Registrarse</b-button>
            <span ref="error"></span>
            </b-form>
        </ValidationObserver>
        <p class="text-center mx-3">
            *Al registrarte aceptas nuestras <router-link :to="{ name:'Condiciones' }"> Condiciones generales</router-link> de uso y venta. Consulta nuestra<router-link :to="{ name:'ProteccionDatos' }">  Política de Privacidad</router-link> y  nuestro<router-link :to="{ name:'Cookies' }"> Política de Cookies</router-link>.
        </p>

    </b-card>
    <div class="my-5">
            <p class="text-center">¿tienes ya una cuenta en Tony Bambino?</p>
            <router-link :to="{ name:'Login' }">
                <b-button class="mb-5 mx-auto d-block font-abril-fatface bg-warning" variant="primary">Iniciar Sesión</b-button>
            </router-link>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Login',
    data() {
      return {
        pass:'',
        repeatpass:'',
        email:'',
        user:'',
        customer:'',
      }
    },
    created(){
        document.title = 'Crear Cuenta en Tony Bambino'
    },
    methods : {
        async onSubmit() {
            const success = await this.$refs.register.validate();

            if (!success) {
                return false
            }

            this.$root.$emit('loader', true)
            if (this.pass !== this.repeatpass || this.pass.length <= 0) {
                this.$refs.register.setErrors({
                    repeatpass: ['Las contraseñas no coinciden']
                })
            }

            let num_cliente = this.customer
            let usuario = this.user
            let email = this.email
            let password = this.pass
            let repeatpass = this.repeatpass
            let tipo = 1

            this.axios.post('register', { num_cliente, usuario, email, tipo, password, repeatpass }).then((response) => {
                let data = response.data
                localStorage.setItem('tbStore.jwt', JSON.stringify(data.token))
                sessionStorage.setItem('tbStore.jwt', JSON.stringify(data.token))
                    if (JSON.parse(localStorage.getItem('tbStore.jwt')) && JSON.parse(sessionStorage.getItem('tbStore.jwt')) !== null) {
                        this.$router.push({ name:'Registered' })
                    }
                this.$root.$emit('loader', false)
            }).catch((error) => {
                if (error.response.status === 404) {
                    this.$refs.register.setErrors({
                        customer: ['El número de cliente ya existe']
                    })
                }
                if (error.response.status === 403) {
                    this.$refs.register.setErrors({
                        user: ['El nombre de usuario ya existe']
                    })
                }
                if (error.response.status === 500) {
                    this.$refs.register.setErrors({
                        email: ['El email introducido ya existe']
                    })
                }
                this.$root.$emit('loader', false)
            })
        },
    }
}
</script>

