<template>
    <div class="z-index load" >
        <div class="loading animated fadeIn mx-auto">
            <div class="bg"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Loader',
}
</script>
